import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Descriptions } from 'antd';
import { RootState } from '../../store/rootReducer';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { AddComma, getPaymentDate } from '../../libs/helper';
import { doGetPreviousMonthSchedule } from '../../store/payment.slice';
import { ScheduleType } from '../../models/Enum';

const TutorPaymentScheduleInfo: FC = () => {
  const {
    tutorPayment,
    paymentSchedule,
    previousMonthScheduleCount,
  } = useSelector((root: RootState) => root.paymentState);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    const filter = paymentSchedule.filter(({ scheduleType }) => scheduleType === ScheduleType.SUCCESS);
    console.log('filter.length: ', filter.length);
    setTotal(filter.length + (tutorPayment?.accumulatedTotalClass ?? 0));
  }, [paymentSchedule, tutorPayment]);

  return (
    <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
      <Descriptions.Item label='기간' span={1}>
        {tutorPayment ? `${getPaymentDate(tutorPayment.paymentMaster.startDateTime)} ~ ${getPaymentDate(tutorPayment.paymentMaster.endDateTime)}` : ''}
      </Descriptions.Item>
      <Descriptions.Item label='이메일' span={1}>
        {tutorPayment?.staff?.email ?? ''}
      </Descriptions.Item>
      <Descriptions.Item label='First Name' span={1}>
        {tutorPayment?.staff?.firstName ?? ''}
      </Descriptions.Item>
      <Descriptions.Item label='Last Name' span={1}>
        {tutorPayment?.staff?.lastName ?? ''}
      </Descriptions.Item>
      <Descriptions.Item label='Base Pay' span={1}>
        ${tutorPayment?.classPerPay.toFixed(2) ?? 0}
      </Descriptions.Item>
      <Descriptions.Item label='Current Tier' span={1}>
        {tutorPayment?.tier ?? 1}
      </Descriptions.Item>
      <Descriptions.Item label='총건수' span={1}>
        {paymentSchedule.length}건
      </Descriptions.Item>
      <Descriptions.Item label='Total' span={1}>
        ${tutorPayment ? AddComma(tutorPayment.totalPay, true) : 0}
      </Descriptions.Item>
      <Descriptions.Item label='전월말 정상 완료 수업수' span={1}>
        {previousMonthScheduleCount}건
      </Descriptions.Item>
      <Descriptions.Item label='누적 정상 완료 수업수' span={1}>
        {total}건
      </Descriptions.Item>
    </Descriptions>
  );
};

export default TutorPaymentScheduleInfo;
