import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {BrotherApplyParams} from "../../models/BrotherApply";
import {getSession} from "../../libs/session";
import {Button, Input, message, Select} from "antd";
import {divStyle, searchFormDivStyle} from "../../style/antdCssProperty";

interface IProps {
  onSubmit(param: BrotherApplyParams): void;
}

const { Option } = Select;
const { Search } = Input;

const CustomerBrotherApplayFilterBox: FC<IProps> = ({ onSubmit }) => {
  const [searchText, setSearchText] = useState('');
  const [searchKey, setSearchKey] = useState('선택');
  const [applyState, setApplyState] = useState('처리상태');

  useEffect(() => {
    const sessionParams = getSession<BrotherApplyParams>('customerBrotherApply');
    if (sessionParams) {
      if (sessionParams.searchKey) {
        setSearchKey(sessionParams.searchKey);
      }
      if (sessionParams.searchText) {
        setSearchText(sessionParams.searchText);
      }
      if (sessionParams.applyState) {
        setApplyState(sessionParams.applyState);
      }
    }
  }, []);

  const onchange_ApplyState = (value: string) => {
    setApplyState(value);
  };

  const onchange_SearchKey = (value: string) => {
    setSearchKey(value);
  };

  const onchange_SearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onDataInit = () => {
    if (searchText && searchKey === '선택') {
      message.warn('타입을 골라주세요.');
    }
    const data: BrotherApplyParams = {
      searchKey: searchKey,
      searchText: searchText.trim(),
      applyState: applyState,
    };
    return data;
  };

  const onSearch = () => {
    const data = onDataInit();
    if (data) {
      onSubmit(data);
    }
  };

  return (
    <div style={divStyle}>
      <div style={searchFormDivStyle}>
        <Select
          style={{width: '10%'}}
          placeholder='select type'
          value={applyState}
          onChange={onchange_ApplyState}>
          <Option value=''>-처리상태-</Option>
          <Option value='apply'>신청</Option>
          <Option value='success'>완료</Option>
          <Option value='reject'>처리불가</Option>
        </Select>
        <Select
          style={{width: '10%'}}
          placeholder='select type'
          value={searchKey}
          onChange={onchange_SearchKey}>
          <Option value='originalName'>원본 회원명</Option>
          <Option value='brotherName'>동생 회원명</Option>
          <Option value='enforcer'>처리자</Option>
          <Option value='email'>아이디</Option>
          <Option value='phone'>전화번호</Option>
        </Select>
        <Search placeholder='input search' value={searchText} onSearch={onSearch} onChange={onchange_SearchText} style={{
          width: '30rem',
          marginRight: '0.5rem',
        }} />
      </div>
      <div>
        <Button type='primary' style={{ marginRight: '0.5rem' }} onClick={onSearch}>검색</Button>
      </div>
    </div>
  );
};

export default CustomerBrotherApplayFilterBox;
