import {AppThunk} from "./index";
import {getDetailInfo, setStateAndReason} from "../api/customerBrotherApply";
import {ApplySaveRequest, BrotherApplyDetailInfo, IGetBrotherApplyDetailInfo} from "../models/BrotherApply";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {messageAdd, showMessage} from "./message.slice";
import {Error} from "../models/Error";

interface IBrotherApplyDetailState {
  detailInfo: BrotherApplyDetailInfo[];
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBrotherApplyDetailState = {
  detailInfo: [],
  isLoading: false,
  error: null,
};

const brotherApplyDetailSlicer = createSlice({
  name: 'brotherApplyDetai',
  initialState,
  reducers: {
    requestStart: (state: IBrotherApplyDetailState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestFailure: (state: IBrotherApplyDetailState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    applyDetailInfoSuccess: (state:IBrotherApplyDetailState, action: PayloadAction<IGetBrotherApplyDetailInfo>) => {
      state.detailInfo = [action.payload.data];
      state.error = null;
      state.isLoading = false;
    },
    notResponseSuccess: (state: IBrotherApplyDetailState) => {
      state.error = null;
      state.isLoading = false;
    },
  }
});

export const {
  requestStart,
  applyDetailInfoSuccess,
  requestFailure,
  notResponseSuccess,
} = brotherApplyDetailSlicer.actions;

export default brotherApplyDetailSlicer.reducer;

export const brotherApplyDetailInfo = (applyNo?: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await getDetailInfo(applyNo);
    const data: IGetBrotherApplyDetailInfo = {
      data: response.data
    };
    dispatch(applyDetailInfoSuccess(data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const brotherApplyStateUpdate = (applyNo: number, data: ApplySaveRequest): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await setStateAndReason(applyNo, data);
    dispatch(messageAdd(showMessage('success', '저장되었습니다.')));
    dispatch(notResponseSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};
