import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ColumnsType } from 'antd/lib/table';
import { AddComma, getPaymentScheduleDate, isFirstLesson } from '../../libs/helper';
import { IPaymentSchedule } from '../../models/payment.model';
import { tableStyle } from '../../style/antdCssProperty';
import { ScheduleType } from '../../models/Enum';
import { setCreditTotal } from '../../store/payment.slice';

interface IProps {
  onDelete(paymentScheduleId: number): void;
}

const BasePaymentTable: FC<IProps> = ({ onDelete }) => {
  const dispatch = useDispatch();

  const { tutorPayment, paymentSchedule } = useSelector((root: RootState) => root.paymentState);
  const { user } = useSelector((root: RootState) => root.authState);

  const [filterSchedule, setFilterSchedule] = useState<IPaymentSchedule[]>([]);

  useEffect(() => {
    const newFilterSchedule = paymentSchedule.filter((item) => item.scheduleType === ScheduleType.SUCCESS);
    setFilterSchedule(newFilterSchedule);
    if (tutorPayment) {
      dispatch(setCreditTotal({ baseCredit: tutorPayment.classPerPay * newFilterSchedule.length }));
    }
  }, [dispatch, tutorPayment, paymentSchedule]);

  const columns: ColumnsType<IPaymentSchedule> = [
    {
      title: '수업시간',
      key: 'startDateTime',
      render: (value, record) => getPaymentScheduleDate(record.startDateTime),
    },
    {
      title: '학생',
      key: 'student',
      render: (value, record) => `${record.student.name}(${record.student.id})`,
    },
    {
      title: '코스/차지',
      key: 'episode',
      render: (value, record) => `${record.episode.name} - Lesson ${isFirstLesson(record.episode.pageNo)}`,
    },
    {
      title: '단가',
      key: 'pay',
      render: (value, record) => {
        return `$${(record.isEvaluated && tutorPayment?.classPerPay) ? tutorPayment.classPerPay.toFixed(2) : '0 (사유: 평가 미작성)'}`;
      },
    },
    {
      title: '',
      render: (value, record) => <Button type='primary' onClick={() => onDelete(record.id)}
                                         disabled={tutorPayment?.paymentMaster.isPublished || user === null || user.id !== 1677}>삭제</Button>,
    },
    {
      title: '',
      render: (value, record) => <Link to={`/dashboard/${record.scheduleId}`}><Button type='primary'>이동</Button></Link>,
    },
  ];

  return (
    <Table
      style={{ ...tableStyle, marginTop: '1.5rem' }}
      dataSource={filterSchedule}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
      title={() => 'Total Classes Taught'}
      summary={(pageData) => <>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0} colSpan={3}>총 {pageData.length}건</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}
                              colSpan={2}>${tutorPayment ? AddComma(tutorPayment.classPerPay * pageData.length, true) : 0}</Table.Summary.Cell>
        </Table.Summary.Row>
      </>}
    />
  );
};

export default BasePaymentTable;
