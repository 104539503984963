import {AxiosPromise} from "axios";
import qs from "qs";
import axios from "./axiosConfig";
import {ApplySaveRequest, BrotherApplyDetailInfo, BrotherApplyList, BrotherApplyParams} from "../models/BrotherApply";

/**
 * 형제회원 신청 리스트 Get API
 * @param param
 */
export const list = (param?: BrotherApplyParams): AxiosPromise<BrotherApplyList[]> => {
  let url = `/api/brother-apply`;
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

/**
 * 형제 회원 신청 상세정보 Get API
 * @param applyNo
 */
export const getDetailInfo = (applyNo?: number): AxiosPromise<BrotherApplyDetailInfo> => {
  return axios.get(`/api/brother-apply/${applyNo}`);
};

/**
 * 형제 회원 신청 정보 상태 & 사유 업데이트 처리
 * @param applyNo
 * @param data
 */
export const setStateAndReason = (applyNo?: number, data?: ApplySaveRequest): AxiosPromise => {
  return axios.patch(`/api/brother-apply/apply-update/${applyNo}`, data);
}
