import { IStudyroomLog, IStutyroomVideo } from '../models/studyroom.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getStudyroom, getStudyroomLogList } from '../api/studyroom.api';
import { act } from 'react-dom/test-utils';

interface ISturyroomState {
  studyroomVideoList: IStutyroomVideo[];
  studyroomLogList: IStudyroomLog[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ISturyroomState = {
  studyroomVideoList: [],
  studyroomLogList: [],
  isLoading: false,
};

const studyroomSlicer = createSlice({
  name: 'studyroom',
  initialState,
  reducers: {
    getStudyroomStart: (state: ISturyroomState) => {
      state.studyroomVideoList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getStudyroomSuccess: (state: ISturyroomState, action: PayloadAction<IStutyroomVideo[]>) => {
      state.studyroomVideoList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getStudyroomFailure: (state: ISturyroomState, action: PayloadAction<AxiosError>) => {
      state.studyroomVideoList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getStudyroomLogListStart: (state: ISturyroomState) => {
      state.studyroomLogList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getStudyroomLogListSuccess: (state: ISturyroomState, action: PayloadAction<IStudyroomLog[]>) => {
      state.studyroomLogList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getStudyroomLogListFailure: (state: ISturyroomState, action: PayloadAction<AxiosError>) => {
      state.studyroomLogList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getStudyroomStart,
  getStudyroomSuccess,
  getStudyroomFailure,
  getStudyroomLogListStart,
  getStudyroomLogListSuccess,
  getStudyroomLogListFailure,
} = studyroomSlicer.actions;
export default studyroomSlicer.reducer;

export const doGetStudyroom = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getStudyroomStart());
    const response = await getStudyroom(scheduleId);
    dispatch(getStudyroomSuccess(response.data));
  } catch (e) {
    dispatch(getStudyroomFailure(e));
  }
};

export const doGetStudyroomLogList = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getStudyroomLogListStart());
    const { data } = await getStudyroomLogList(scheduleId);
    dispatch(getStudyroomLogListSuccess(data));
  } catch (e) {
    dispatch(getStudyroomLogListFailure(e));
  }
};
