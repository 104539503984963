import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import {Breadcrumb, Button, Col, PageHeader, Pagination, Row} from "antd";
import {Link} from "react-router-dom";
import BooksOrderLogModal from "../components/BooksOrder/BooksOrderLogModal";
import {getSession, setSession} from "../libs/session";
import {booksOrderLogData} from "../store/booksOrderLog.slice";
import {FileExcelOutlined} from "@ant-design/icons";
import {
  BooksSubscribeOrderSessionName,
  IBooksSubscribeOrderExcelData,
  IBooksSubscribeOrderParams
} from "../models/BooksSubscribeOrder";
import {booksSubscribeOrderDataSearch} from "../store/booksSubscribeOrder.slice";
import {subscribeOrderDataSearchList} from "../api/booksOrder";
import {messageAdd, showMessage} from "../store/message.slice";
import {BooksOrderStateList} from "../models/BooksOrder";
import {ExcelDown} from "../libs/excelDown";
import BooksSubscribeOrderFilterBox from "../components/Transaction/BooksSubscribeOrderFilterBox";
import BooksSubscribeOrderListTable from "../components/BooksSubscribeOrderListTable";
import {getBooksOrderDetailData} from "../store/booksOrder.slice";
import BooksOrderDetailInfoModal from "../components/BooksOrder/BooksOrderDetailInfoModal";
import {format} from "date-fns";
import BooksOrderAddressChangeModal from "../components/BooksOrder/BooksOrderAddressChangeModal";

const BooksSubscribeOrderPage: FC = () => {
  const dispatch = useDispatch();
  const {dataList, total} = useSelector((root: RootState) => root.booksSubscribeOrderState);
  const {logDataList} = useSelector((root: RootState) => root.booksOrderLogState);
  const {orderDetailInfo, orderUserId} = useSelector((root: RootState) => root.booksOrderState);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState<IBooksSubscribeOrderParams>();
  const [current, setCurrent] = useState(1);
  const [orderLogModalVisible, setOrderLogModalVisible] = useState(false);
  const [orderDetailModalVisible, setOrderDetailModalVisible] = useState(false);
  const [orderAddressChangeModalVisible, setOrderAddressChangeModalVisible] = useState(false);
  const [selectUserId, setSelectUserId] = useState(0);
  const [selectBooksOrderNo, setSelectBooksOrderNo] = useState(0);
  const [selectBooksOrderComponentsNo, setSelectBooksOrderComponentsNo] = useState(0);

  const onSubmit = (params: IBooksSubscribeOrderParams): void => {
    params.page = 1;
    setPage(params.page);
    setParams(params);
    setSession(BooksSubscribeOrderSessionName, params);
    current === 1 ? dispatch(booksSubscribeOrderDataSearch(params)) : setCurrent(1);
  };

  const onPageChange = (page: number) => {
    const newParams: IBooksSubscribeOrderParams = {
      ...params,
      page,
    };
    setSession(BooksSubscribeOrderSessionName, newParams);
    setParams(newParams);
    setCurrent(page);
    setPage(page);
  };

  const orderLogModalShow = async (boNo: number, view: boolean, bocNo?: number) => {
    setOrderLogModalVisible(view);
    await dispatch(booksOrderLogData({bo_no: boNo, boc_no: bocNo}));
  };

  const orderDetailModalShow = async (boNo: number, userId: number, view: boolean) => {
    setOrderDetailModalVisible(view);
    await dispatch(getBooksOrderDetailData(boNo, userId));
  };

  const orderAddressChangeModalShow = async (boNo: number, bocNo: number, userId: number, view: boolean) => {
    orderDetailModalClose();
    setSelectUserId(userId);
    setSelectBooksOrderNo(boNo);
    setSelectBooksOrderComponentsNo(bocNo);
    setOrderAddressChangeModalVisible(view);
  };

  const orderLogModalClose = () => {
    setOrderLogModalVisible(false);
  };

  const orderDetailModalClose = () => {
    setOrderDetailModalVisible(false);
  };

  const orderAddressChangeModalClose = () => {
    setSelectUserId(0);
    setSelectBooksOrderNo(0);
    setSelectBooksOrderComponentsNo(0);
    setOrderAddressChangeModalVisible(false);
  };

  const onPageDataChange = () => {
    dispatch(booksSubscribeOrderDataSearch(params));
  };

  const onBooksOrderDetailInfoModalDataChange = (boNo: number, userId: number) => {
    dispatch(getBooksOrderDetailData(boNo, userId));
    dispatch(booksSubscribeOrderDataSearch(params));
  };

  const onBooksOrderAddressModalDataChange = () => {
    dispatch(booksSubscribeOrderDataSearch(params));
  };

  const onExcelClick = async () => {
    const newParams: IBooksSubscribeOrderParams = {
      ...params,
      excelDownUse: true,
    };

    const response = await subscribeOrderDataSearchList(newParams);
    if (response.data.length < 1) {
      dispatch(messageAdd(showMessage('error', '검색된 데이터가 없습니다.')));
      return;
    }

    const data: IBooksSubscribeOrderExcelData[] = [];

    for (const item of response.data) {
      data.push({
        bo_addr1: item.bo_addr1,
        bo_addr2: item.bo_addr2,
        bo_addrNum: item.bo_addrNum,
        bo_cancelDate: item.bo_cancelDate ? format(new Date(item.bo_cancelDate), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_cancelRequestDate: item.bo_cancelRequestDate ? format(new Date(item.bo_cancelRequestDate), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_cancelSubscribeDate: item.bo_cancelSubscribeDate ? format(new Date(item.bo_cancelSubscribeDate), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_createdAt: item.bo_createdAt ? format(new Date(item.bo_createdAt), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_deletedAt: item.bo_deletedAt ? format(new Date(item.bo_deletedAt), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_email: item.bo_email,
        bo_goodsName: item.bo_goodsName,
        bo_isAutoPay: item.bo_isAutoPay,
        bo_name: item.bo_name,
        bo_no: item.bo_no,
        bo_orderGoodsType: item.bo_orderGoodsType,
        bo_paymethod: item.bo_paymethod,
        bo_phone: item.bo_phone,
        bo_state: BooksOrderStateList[item.bo_state],
        bo_updatedAt: item.bo_updatedAt ? format(new Date(item.bo_updatedAt), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_userId: item.bo_userId,
        boc_applyNo: item.boc_applyNo,
        boc_invoiceCompany: item.boc_invoiceCompany,
        boc_invoiceNum: item.boc_invoiceNum,
        boc_no: item.boc_no,
        boc_price: item.boc_price,
        u_id: item.u_id,
        s_name: item.s_name,
        bo_studentId: item.bo_studentId,
        maxSubscribeMonth: item.maxSubscribeMonth,
      });
    }

    await ExcelDown('구독 주문 리스트', [
      {header: "주문 코드", key: "bo_no"},
      {header: "이름", key: "bo_name"},
      {header: "자녀명", key: "s_name"},
      {header: "ID(Email)", key: "bo_email"},
      {header: "전화번호", key: "bo_phone"},
      {header: "우편번호", key: "bo_addrNum"},
      {header: "주소1", key: "bo_addr1"},
      {header: "주소2", key: "bo_addr2"},
      {header: "주문 제품", key: "bo_goodsName"},
      {header: "구독 시작일", key: "bo_createdAt"},
      {header: "취소 완료일", key: "bo_cancelDate"},
      {header: "구독 주문 상태", key: "bo_state"},
      {header: "앱 이용기간", key: ""},
      {header: "결제 회차", key: "maxSubscribeMonth"},
    ], data);
  };

  useEffect(() => {
    const sessionParams = getSession<IBooksSubscribeOrderParams>(BooksSubscribeOrderSessionName);
    if (sessionParams) {
      if (sessionParams.page) {
        setCurrent(sessionParams.page);
      }
      dispatch(booksSubscribeOrderDataSearch(sessionParams));
    } else {
      const data: IBooksSubscribeOrderParams = {
        ...params,
        page: current,
      };
      dispatch(booksSubscribeOrderDataSearch(data));
    }
  }, [dispatch, current]);


  useEffect(() => {
    if (params) {
      setSession(BooksSubscribeOrderSessionName, params);
    }
  }, []);

  return (<>
    <Breadcrumb>
      <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
      <Breadcrumb.Item>결제관리</Breadcrumb.Item>
    </Breadcrumb>
    <PageHeader title='[구독] 구독 주문관리'/>
    <BooksSubscribeOrderFilterBox onSubmit={onSubmit}/>
    <div style={{height: 35}}>
      <span>total: {total}</span>
      <Button type='primary'
              icon={<FileExcelOutlined/>}
              style={{float: 'right'}}
              onClick={onExcelClick}>
        엑셀다운로드
      </Button>
    </div>
    <Row gutter={10}>
      <Col span={24}>
        <BooksSubscribeOrderListTable
          dataList={dataList ? dataList : []}
          orderLogModalShow={orderLogModalShow}
          onChange={onPageDataChange}
          orderDetailInfoModalShow={orderDetailModalShow}
          orderAddressChangeModalShow={orderAddressChangeModalShow}/>
      </Col>
    </Row>
    <Pagination current={page} total={total} defaultPageSize={20} onChange={onPageChange}
                style={{marginTop: '1.5rem'}} showSizeChanger={false}/>
    <BooksOrderLogModal
      visible={orderLogModalVisible}
      orderLogInfoData={logDataList}
      onCancel={orderLogModalClose}/>
    <BooksOrderDetailInfoModal
      visible={orderDetailModalVisible}
      orderInfoData={orderDetailInfo}
      orderUserId={orderUserId}
      onCancel={orderDetailModalClose}
      onChange={onBooksOrderDetailInfoModalDataChange}
      orderAddressChangeModalShow={orderAddressChangeModalShow}/>
    <BooksOrderAddressChangeModal
      visible={orderAddressChangeModalVisible}
      userId={selectUserId}
      boNo={selectBooksOrderNo}
      bocNo={selectBooksOrderComponentsNo}
      onCancel={orderAddressChangeModalClose}
      onChange={onBooksOrderAddressModalDataChange}/>
  </>);
};

export default BooksSubscribeOrderPage;
