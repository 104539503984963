import {
  BooksSubscribeOrderSearchKeyList,
  BooksSubscribeOrderSearchStateList, BooksSubscribeOrderSessionName,
  IBooksSubscribeOrderParams
} from "../../models/BooksSubscribeOrder";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Button, Input, Select} from "antd";
import {getSession} from "../../libs/session";
import {divStyle, searchFormDivStyle} from "../../style/antdCssProperty";
import CustomDatePicker from "../common/CustomDatePicker";
import {SearchOutlined} from "@ant-design/icons";

interface IProps {
  onSubmit(params?: IBooksSubscribeOrderParams): void;
}

const {Option} = Select;
const {Search} = Input;

const BooksSubscribeOrderFilterBox: FC<IProps> = ({onSubmit}) => {
  const [searchDateS, setSearchDateS] = useState<string>();
  const [searchDateE, setSearchDateE] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchText, setSearchText] = useState<string>();
  const [searchState, setSearchState] = useState<string>('all');

  useEffect(() => {
    const sessionParams = getSession<IBooksSubscribeOrderParams>(BooksSubscribeOrderSessionName);
    if (sessionParams) {
      if (sessionParams.searchDateS) {
        setSearchDateS(sessionParams.searchDateS);
      }
      if (sessionParams.searchDateE) {
        setSearchDateE(sessionParams.searchDateE);
      }
      if (sessionParams.searchKey) {
        setSearchKey(sessionParams.searchKey);
      }
      if (sessionParams.searchText) {
        setSearchText(sessionParams.searchText);
      }
      if (sessionParams.searchState) {
        setSearchState(sessionParams.searchState);
      }
    }
  }, []);

  const onSearchDateSChange = (date: Date | null, dateString: string) => {
    setSearchDateS(dateString);
  };

  const onSearchDateEChange = (date: Date | null, dateString: string) => {
    setSearchDateE(dateString);
  };

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onSearchKeyChange = (value: string) => {
    setSearchKey(value);
  };

  const onSearchStateChange = (value: string) => {
    setSearchState(value);
  };

  const onDataInit = () => {
    const data: IBooksSubscribeOrderParams = {
      searchDateE: searchDateE,
      searchDateS: searchDateS,
      searchKey: searchKey,
      searchState: searchState,
      searchText: searchText,
    };
    return data;
  };

  const onSearch = () => {
    const data = onDataInit();
    if (data) {
      onSubmit(data);
    }
  };

  return (
    <>
      <div style={divStyle}>
        <div style={searchFormDivStyle}>
          <div>
            <span style={{marginRight: '0.5rem'}}>구독 시작일 :</span>
            <CustomDatePicker value={searchDateS ? new Date(searchDateS) : new Date(new Date().setMonth(-6))}
                              onChange={onSearchDateSChange}/>
            <span style={{marginRight: '0.5rem', marginLeft: '0.5rem'}}>~</span>
            <CustomDatePicker value={searchDateE ? new Date(searchDateE) : new Date()} onChange={onSearchDateEChange}/>
          </div>
          <div>
            <span style={{marginRight: '0.5rem'}}>구독 주문 상태 :</span>
            <Select
              style={{width: '10%'}}
              placeholder='select type'
              value={searchState}
              onChange={onSearchStateChange}>
              <Option value='all'>-전체-</Option>
              {
                Object.entries(BooksSubscribeOrderSearchStateList).map((item) =>
                  <Option value={item[0]} key={`searchState-${item[0]}`}>{item[1]}</Option>
                )
              }
            </Select>
          </div>
          <div>
            <span style={{marginRight: '0.5rem'}}>키워드 검색 :</span>
            <Select
              style={{width: '10%'}}
              placeholder='select type'
              value={searchKey}
              onChange={onSearchKeyChange}>
              <Option value='all'>-전체-</Option>
              {
                Object.entries(BooksSubscribeOrderSearchKeyList).map((item) =>
                  <Option value={item[0]} key={`searchKey-${item[0]}`}>{item[1]}</Option>
                )
              }
            </Select>
            <Search placeholder='input search' value={searchText} onSearch={onSearch} onChange={onSearchTextChange}
                    style={{
                      width: '30rem',
                      marginRight: '0.5rem',
                    }}/>
          </div>
        </div>
        <div>
          <Button type='primary' icon={<SearchOutlined/>} style={{marginRight: '0.5rem'}} onClick={onSearch}>검색</Button>
        </div>
      </div>
    </>
  );
}

export default BooksSubscribeOrderFilterBox;
