import {
  IBooksSingleOrderDataList,
  IBooksSingleOrderDataListResponse,
  IBooksSingleOrderParams, ICancelSingleOrderParams, ICancelSingleOrderResponse
} from "../models/BooksSingleOrder";
import {AppThunk} from "./index";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {requestCancelSingleOrder, singleOrderDataSearchList} from "../api/booksOrder";
import {Error} from "../models/Error";
import {messageAdd, showMessage} from "./message.slice";

export interface IBooksSingleOrderState {
  dataList: IBooksSingleOrderDataList[] | null,
  total: number;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBooksSingleOrderState = {
  dataList: [],
  total: 0,
  isLoading: false,
  error: null,
};

const booksSingleOrderSlice = createSlice({
  name: 'booksSingleOrder',
  initialState,
  reducers: {
    requestStart: (state: IBooksSingleOrderState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestFailure: (state: IBooksSingleOrderState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    dataListSuccess: (state: IBooksSingleOrderState, action: PayloadAction<IBooksSingleOrderDataListResponse>) => {
      state.total = action.payload.total;
      state.dataList = action.payload.dataList;
      state.error = null;
      state.isLoading = false;
    },
    cancelRequest: (state: IBooksSingleOrderState, action: PayloadAction<ICancelSingleOrderResponse>) => {
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestFailure,
  dataListSuccess,
  cancelRequest,
} = booksSingleOrderSlice.actions;

export default booksSingleOrderSlice.reducer;

export const booksSingleOrderDataList = (params?: IBooksSingleOrderParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await singleOrderDataSearchList(params);
    const data: IBooksSingleOrderDataListResponse = {
      dataList: response.data,
      total: response.headers['total'],
    };
    dispatch(dataListSuccess(data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const cancelSingleOrder = (params: ICancelSingleOrderParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await requestCancelSingleOrder(params);
    const data: ICancelSingleOrderResponse = {
      result: response.data.result,
      resultMessage: response.data.resultMessage,
    };
    dispatch(cancelRequest(data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};
