import { FC, useEffect, useState } from 'react';
import { Descriptions, InputNumber, Button } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { IUserTransaction } from '../../models/UserTransaction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import CustomDatePicker from '../common/CustomDatePicker';
import { doPatchUserTransaction, setUserTransactionBody } from '../../store/userTransaction.slice';
import styled from 'styled-components';
import CustomerAddClassCountModal from './CustomerAddClassCountModal';


const TotalClassCountContainer = styled.div`
  display: flex;

  & > button {
    margin-left: auto;
    margin-right: 0;
  }
`;

const SaveButtonContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  userTransaction: IUserTransaction;
}

const CustomerUserTransactionEdit: FC<IProps> = ({
                                                   userTransaction: {
                                                     id,
                                                     course,
                                                     amount,
                                                     startDate,
                                                     endDate,
                                                     totalClassCount,
                                                     usedClassCount,
                                                     cancelClassCount,
                                                   },
                                                 }) => {
  const dispatch = useDispatch();

  const { body } = useSelector((state: RootState) => state.userTransactionState);

  const [visible, setVisible] = useState(false);

  const onStartDateChange = (value: (Date | null), dateString: string) => dispatch(setUserTransactionBody({
    ...body,
    startDate: dateString,
  }));

  const onEndDateChange = (value: (Date | null), dateString: string) => dispatch(setUserTransactionBody({
    ...body,
    endDate: dateString,
  }));

  const onCancelClassCountChange = (value: (string | number | null | undefined)) => dispatch(setUserTransactionBody({
    ...body,
    cancelClassCount: value ? +value : undefined,
  }));

  const showModal = () => setVisible(true);

  const onOk = () => {
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onSubmit = () => {
    if (!body) return;
    dispatch(doPatchUserTransaction(id, body));
  };

  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='과정' span={1}>{course.name}</Descriptions.Item>
        <Descriptions.Item label='금액' span={1}>{amount}</Descriptions.Item>
        <Descriptions.Item label='수업 시작일' span={1}><CustomDatePicker onChange={onStartDateChange} allowClear={false}
                                                                     value={new Date(body?.startDate ?? startDate)} /></Descriptions.Item>
        <Descriptions.Item label='수업 종료일' span={1}><CustomDatePicker onChange={onEndDateChange} allowClear={false}
                                                                     value={new Date(body?.endDate ?? endDate)} /></Descriptions.Item>
        <Descriptions.Item label='총 수업 개수' span={1}>
          <TotalClassCountContainer>
            {totalClassCount - usedClassCount}
            <Button type='primary' onClick={showModal}>수업권 추가</Button>
          </TotalClassCountContainer>
        </Descriptions.Item>
        <Descriptions.Item label='취소 예약 수' span={1}>
          <InputNumber
            value={body?.cancelClassCount ?? cancelClassCount} onChange={onCancelClassCountChange} />
        </Descriptions.Item>
      </Descriptions>
      <SaveButtonContainer>
        <Button type='primary' onClick={onSubmit}>저장</Button>
      </SaveButtonContainer>
      <CustomerAddClassCountModal visible={visible} onOk={onOk} onCancel={onCancel} />
    </>
  );
};

export default CustomerUserTransactionEdit;
