import {
  IDashboardRead, IDashboardReadList, IDashboardReadListParam,
  IDashboardSchedule, IDashboardScheduleParam, IDashboardViewChangeUpdate, IScheduleFail, IStatic,
  StudentScheduleLmsListItem, StudentScheduleLmsQuery,
  TutorScheduleEvaluationItem,
  TutorScheduleFeedbackItem,
  TutorScheduleLmsHistoryListItem,
  TutorScheduleLmsHistoryQuery,
  TutorScheduleLmsListItem,
  TutorScheduleLmsQuery,
  IDashboardScheduleListSuccess,
} from '../models/Schedule';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import {
  dashboardScheduleList,
  evaluationRead,
  feedbackRead, getDashboardSchedule, getDashboardScheduleList, getStaticList,
  lmsHistoryList,
  lmsList,
  lmsStudentList, patchDashboardSchedule, patchScheduleFail,
} from '../api/schedule';
import { message } from 'antd';

interface IScheduleState {
  lmsSchedule: TutorScheduleLmsListItem[] | null;
  lmsHistorySchedule: TutorScheduleLmsHistoryListItem[] | null;
  feedback: TutorScheduleFeedbackItem | null;
  evaluation: TutorScheduleEvaluationItem | null;
  lmsStudentSchedule: StudentScheduleLmsListItem[] | null;
  dashboardScheduleList: IDashboardSchedule[];
  dashboardRead?: IDashboardRead;
  dashboardReadList: IDashboardReadList[];
  statics?: IStatic;
  isLoading: boolean;
  scheduleLoading: boolean;
  error: AxiosError | null;
  total?: number;
  isGetSuccess: boolean;
}

const initialState: IScheduleState = {
  lmsSchedule: null,
  lmsHistorySchedule: null,
  feedback: null,
  evaluation: null,
  lmsStudentSchedule: null,
  dashboardScheduleList: [],
  dashboardReadList: [],
  isLoading: false,
  scheduleLoading: false,
  error: null,
  isGetSuccess: false,
};

const scheduleSlicer = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    requestStart: (state: IScheduleState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestLmsListSuccess: (state: IScheduleState, action: PayloadAction<TutorScheduleLmsListItem[]>) => {
      state.lmsSchedule = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestLmsHistoryListSuccess: (state: IScheduleState, action: PayloadAction<TutorScheduleLmsHistoryListItem[]>) => {
      state.lmsHistorySchedule = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestFeedbackReadSuccess: (state: IScheduleState, action: PayloadAction<TutorScheduleFeedbackItem>) => {
      state.feedback = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestEvaluationReadSuccess: (state: IScheduleState, action: PayloadAction<TutorScheduleEvaluationItem>) => {
      state.evaluation = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestLmsListStudentSuccess: (state: IScheduleState, action: PayloadAction<StudentScheduleLmsListItem[]>) => {
      state.lmsStudentSchedule = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestFailure: (state: IScheduleState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.scheduleLoading = false;
    },
    dashboardScheduleListStart: (state: IScheduleState) => {
      state.isLoading = true;
      state.error = null;
    },
    dashboardScheduleListSuccess: (state: IScheduleState, action: PayloadAction<IDashboardSchedule[]>) => {
      console.log('action.payload: ', action.payload);
      state.isGetSuccess = true;
      state.dashboardScheduleList = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    dashboardScheduleListFailure: (state: IScheduleState, action: PayloadAction<AxiosError>) => {
      state.dashboardScheduleList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getDashboardScheduleStart: (state: IScheduleState) => {
      state.dashboardRead = undefined;
      state.isLoading = true;
      state.error = null;
    },
    getDashboardScheduleSuccess: (state: IScheduleState, action: PayloadAction<IDashboardRead>) => {
      state.dashboardRead = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getDashboardScheduleFailure: (state: IScheduleState, action: PayloadAction<AxiosError>) => {
      state.dashboardRead = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getDashboardScheduleListStart: (state: IScheduleState) => {
      state.dashboardReadList = [];
      state.isLoading = true;
      state.error = null;
    },
    getDashboardScheduleListSuccess: (state: IScheduleState, action: PayloadAction<IDashboardReadList[]>) => {
      state.dashboardReadList = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getDashboardScheduleListFailure: (state: IScheduleState, action: PayloadAction<AxiosError>) => {
      state.dashboardReadList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    patchDashboardScheduleStart: (state: IScheduleState) => {
      state.isLoading = true;
      state.error = null;
    },
    patchDashboardScheduleSuccess: (state: IScheduleState, action: PayloadAction<IDashboardRead>) => {
      state.dashboardRead = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    patchDashboardScheduleFailure: (state: IScheduleState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    patchScheduleFailStart: (state: IScheduleState) => {
      state.isLoading = true;
      state.error = null;
    },
    patchScheduleFailSuccess: (state: IScheduleState, action: PayloadAction<IDashboardRead>) => {
      state.dashboardRead = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    patchScheduleFailFailure: (state: IScheduleState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    levelTestRecoverySuccess: (state: IScheduleState) => {
      if (state.dashboardRead) {
        state.dashboardRead = {
          ...state.dashboardRead,
          levelTest: { deletedAt: new Date().toISOString() },
        };
      }
    },
    getStaticListStart: (state: IScheduleState) => {
      state.statics = undefined;
      state.isLoading = true;
      state.error = null;
    },
    getStaticListSuccess: (state: IScheduleState, { payload }: PayloadAction<IStatic>) => {
      state.statics = payload;
      state.isLoading = false;
      state.error = null;
    },
    getStaticListFailure: (state: IScheduleState, { payload }: PayloadAction<AxiosError>) => {
      state.statics = undefined;
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  requestStart,
  requestLmsListSuccess,
  requestLmsHistoryListSuccess,
  requestFeedbackReadSuccess,
  requestEvaluationReadSuccess,
  requestLmsListStudentSuccess,
  requestFailure,
  dashboardScheduleListStart,
  dashboardScheduleListSuccess,
  dashboardScheduleListFailure,
  getDashboardScheduleStart,
  getDashboardScheduleSuccess,
  getDashboardScheduleFailure,
  getDashboardScheduleListStart,
  getDashboardScheduleListSuccess,
  getDashboardScheduleListFailure,
  patchDashboardScheduleStart,
  patchDashboardScheduleSuccess,
  patchDashboardScheduleFailure,
  patchScheduleFailStart,
  patchScheduleFailSuccess,
  patchScheduleFailFailure,
  levelTestRecoverySuccess,
  getStaticListStart,
  getStaticListSuccess,
  getStaticListFailure,
} = scheduleSlicer.actions;

export default scheduleSlicer.reducer;

export const scheduleLmsList = (staffId: number, query: TutorScheduleLmsQuery): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await lmsList(staffId, query);
    dispatch(requestLmsListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const scheduleLmsHistoryList = (staffId: number, query: TutorScheduleLmsHistoryQuery): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await lmsHistoryList(staffId, query);
    dispatch(requestLmsHistoryListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const scheduleFeedbackRead = (id: number): AppThunk => async (dispatch) => {
  try {
    const response = await feedbackRead(id);
    dispatch(requestFeedbackReadSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const scheduleEvaluationRead = (id: number): AppThunk => async (dispatch) => {
  try {
    const response = await evaluationRead(id);
    dispatch(requestEvaluationReadSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const scheduleStudentLmsList = (staffId: number, query: StudentScheduleLmsQuery): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await lmsStudentList(staffId, query);
    dispatch(requestLmsListStudentSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doDashboardScheduleList = (params: IDashboardScheduleParam): AppThunk => async (dispatch) => {
  try {
    dispatch(dashboardScheduleListStart());
    const response = await dashboardScheduleList(params);
    console.log('params: ', params);
    console.log('response.data: ', response.data);
    dispatch(dashboardScheduleListSuccess(response.data));
  } catch (e) {
    dispatch(dashboardScheduleListFailure(e));
  }
};

export const doGetDashboardSchedule = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getDashboardScheduleStart());
    const response = await getDashboardSchedule(id);
    dispatch(getDashboardScheduleSuccess(response.data));
  } catch (e) {
    dispatch(getDashboardScheduleFailure(e));
  }
};

export const doGetDashboardScheduleList = (data: IDashboardReadListParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getDashboardScheduleListStart());
    const response = await getDashboardScheduleList(data);
    dispatch(getDashboardScheduleListSuccess(response.data));
    if (response.data.length === 0) {
      message.info('바꿀수 있는 강사가 존재하지 않습니다.');
    }
  } catch (e) {
    dispatch(getDashboardScheduleListFailure(e));
  }
};

export const doPatchDashboardSchedule = (data: IDashboardViewChangeUpdate): AppThunk => async (dispatch) => {
  try {
    dispatch(patchDashboardScheduleStart());
    const response = await patchDashboardSchedule(data);
    dispatch(patchDashboardScheduleSuccess(response.data));
  } catch (e) {
    dispatch(patchDashboardScheduleFailure(e));
  }
};

export const doPatchScheduleFail = (id: number, body: IScheduleFail): AppThunk => async (dispatch) => {
  try {
    dispatch(patchScheduleFailStart());
    const { data } = await patchScheduleFail(id, body);
    dispatch(patchScheduleFailSuccess(data));
    message.success('수업실패처리에 성공했습니다.');
  } catch (e) {
    dispatch(patchScheduleFailFailure(e));
  }
};

export const doGetStaticList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getStaticListStart());
    const { data } = await getStaticList();
    dispatch(getStaticListSuccess(data));
  } catch (e) {
    dispatch(getStaticListFailure(e));
  }
};
