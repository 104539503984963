import {AxiosError} from "axios";
import {
  IBooksSubscribeOrderDataList,
  IBooksSubscribeOrderParams,
  IBooksSubscribeOrderReponse, IOrderCancelSubscribeResponse
} from "../models/BooksSubscribeOrder";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./index";
import {orderCancelSubscribe, subscribeOrderDataSearchList, subscribeOrderMonthCancel} from "../api/booksOrder";
import {Error} from "../models/Error";
import {messageAdd, showMessage} from "./message.slice";
import {ISubscribeOrderMonthCancelParams, ISubscribeOrderMonthCancelResponse} from "../models/BooksOrder";

export interface IBooksSubscribeOrderState {
  dataList: IBooksSubscribeOrderDataList[] | null;
  total: number;
  isLoading: boolean;
  error: AxiosError | null;
  result: boolean;
  resultMessage: string;
}

const initialState: IBooksSubscribeOrderState = {
  dataList: [],
  total: 0,
  error: null,
  isLoading: false,
  result: true,
  resultMessage: '',
}

const BooksSubscribeOrderSlice = createSlice({
  name: 'BooksSubscribeOrder',
  initialState,
  reducers: {
    requestStart: (state: IBooksSubscribeOrderState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestFailure: (state: IBooksSubscribeOrderState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    dataListSuccess: (state: IBooksSubscribeOrderState, action: PayloadAction<IBooksSubscribeOrderReponse>) => {
      state.error = null;
      state.isLoading = false;
      state.dataList = action.payload.dataList;
      state.total = action.payload.total;
    },
    orderCancelSubscribe: (state: IBooksSubscribeOrderState, action: PayloadAction<IOrderCancelSubscribeResponse>) => {
      state.error = null;
      state.isLoading = false;
      state.result = action.payload.result;
      state.resultMessage = action.payload.resultMessage;
    },
  },
});

export const {
  requestStart,
  requestFailure,
  dataListSuccess,
} = BooksSubscribeOrderSlice.actions;

export default BooksSubscribeOrderSlice.reducer;

export const booksSubscribeOrderDataSearch = (params?: IBooksSubscribeOrderParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await subscribeOrderDataSearchList(params);
    const data: IBooksSubscribeOrderReponse = {
      dataList: response.data,
      total: response.headers['total'],
    };
    dispatch(dataListSuccess(data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
}

export const setBooksOrderCancelSubscribe = (userId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await orderCancelSubscribe(userId);
    dispatch(messageAdd(showMessage(response.data.result ? 'success' : 'error', response.data.resultMessage)));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
}

export const setSubscribeOrderMonthCancel = (params: ISubscribeOrderMonthCancelParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await subscribeOrderMonthCancel(params);
    dispatch(messageAdd(showMessage(response.data.result ? 'success' : 'error', response.data.resultMessage)));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
}
