/**
 * 검색 Session Name
 */
export const BooksSubscribeOrderSessionName = 'booksSubscribeOrderParams';
/**
 * 구독 주문 검색 Params
 */
export interface IBooksSubscribeOrderParams {
  page?: number;
  searchDateS?: string;
  searchDateE?: string;
  searchKey?: string;
  searchText?: string;
  searchState?: string;
  excelDownUse?: boolean;
}

/**
 * 구독 주문 검색 정보 리스트
 */
export interface IBooksSubscribeOrderDataList {
  bo_no: number;
  bo_state: number;
  bo_paymethod: number;
  bo_isAutoPay: number;
  bo_cancelRequestDate: string;
  bo_cancelDate: string;
  bo_goodsName: string;
  bo_name: string;
  bo_email: string;
  bo_phone: string;
  bo_addr1: string;
  bo_addr2: string;
  bo_addrNum: string;
  bo_orderGoodsType: number;
  bo_cancelSubscribeDate: string;
  bo_createdAt: string;
  bo_updatedAt: string;
  bo_deletedAt: string;
  bo_userId: number,
  boc_applyNo: string;
  boc_price: number,
  boc_invoiceNum: string;
  boc_invoiceCompany: string;
  u_id: number;
  boc_no: number;
  bo_studentId: number;
  s_name: string;
  maxSubscribeMonth: number;
}

/**
 * 구독 주문 검색 API Response
 */
export interface IBooksSubscribeOrderReponse {
  dataList: IBooksSubscribeOrderDataList[];
  total: number;
}

/**
 * 구독 주문 검색 엑셀 다운로드 데이터
 */
export interface IBooksSubscribeOrderExcelData {
  bo_no: number;
  bo_state: string;
  bo_paymethod: number;
  bo_isAutoPay: number;
  bo_cancelRequestDate: string;
  bo_cancelDate: string;
  bo_goodsName: string;
  bo_name: string;
  bo_email: string;
  bo_phone: string;
  bo_addr1: string;
  bo_addr2: string;
  bo_addrNum: string;
  bo_orderGoodsType: number;
  bo_cancelSubscribeDate: string;
  bo_createdAt: string;
  bo_updatedAt: string;
  bo_deletedAt: string;
  bo_userId: number,
  boc_applyNo: string;
  boc_price: number,
  boc_invoiceNum: string;
  boc_invoiceCompany: string;
  u_id: number;
  boc_no: number;
  bo_studentId: number;
  s_name: string;
  maxSubscribeMonth: number;
}

/**
 * 구독 주문 상태 검색 List
 */
export const BooksSubscribeOrderSearchStateList = {
  1: '결제 완료',
  2: '출고',
  3: '자동결제실패',
  4: '취소접수',
  5: '취소완료',
  6: '취소기각',
  7: '취소가능',
  8: '구독취소',
};

/**
 * 구독 주문 키워드 검색 List
 */
export const BooksSubscribeOrderSearchKeyList = {
  phone: '전화번호',
  name: '이름',
  email: '아이디',
  invoiceNum: '운송장번호',
  studentName: '자녀명',
};

/**
 * 구독 주문 구독 취소 처리 Response Interface
 */
export interface IOrderCancelSubscribeResponse {
  result: boolean;
  resultMessage: string;
}
