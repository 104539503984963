import React, { FC, useEffect, useState } from 'react';
import { Breadcrumb, Tabs, message,Button } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import TutorInfo from '../components/TutorMgmt/TutorInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import TutorScheduleMaster from '../components/TutorMgmt/TutorScheduleMaster';
import { tutorScheduleMasterList } from '../store/tutorScheduleMaster.slice';
import TutorScheduleDetail from '../components/TutorMgmt/TutorScheduleDetail';
import {
  scheduleLmsHistoryList,
  scheduleLmsList,
} from '../store/schedule.slice';
import { TutorScheduleLmsHistoryQuery, TutorScheduleLmsQuery } from '../models/Schedule';
import { dateFormat } from '../libs/helper';
import TutorBoard from '../components/TutorMgmt/TutorBoard';
import { doGetTutor2, doUpdateTutor2 } from '../store/tutor2.slice';
import TutorContractTable from '../components/TutorMgmt/TutorContractTable';
import { doGetContractList, doPatchContract, doPostContract } from '../store/contract.slice';
import TutorContractEditor from '../components/TutorMgmt/TutorContractEditor';
import { IContractSubmit } from '../models/Contract';
import TutorWorkingLogTable from '../components/TutorMgmt/TutorWorkingLogTable';
import { doGetTutorWorkingLogList } from '../store/tutorWorkingLog.slice';
import { ITutorWorkingLogParam } from '../models/TutorWorkingLog';
import { divStyle } from '../style/antdCssProperty';
import { getStaffVirtualLogin } from '../api/auth';

interface IMatch {
  id: string;
}

const { TabPane } = Tabs;

const TutorPage: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const { tutor, isLoading: tutor2IsLoading } = useSelector((state: RootState) => state.tutor2State);
  const {
    isLoading: tutorScheduleMasterIsLoading,
    tutorScheduleMasters,
  } = useSelector((state: RootState) => state.tutorScheduleMasterState);
  const { isLoading: boardIsLoading, boards } = useSelector((state: RootState) => state.boardState);
  const {
    isLoading: contractIsLoading,
    contractList,
    error: contractError,
  } = useSelector((state: RootState) => state.contractState);
  const {
    isLoading: tutorWorkingLogIsLoading,
    tutorWorkingLogList,
  } = useSelector((state: RootState) => state.tutorWorkingLogState);
  const [staffId, setStaffId] = useState(0);
  const [isUpdate, setIsUpdate] = useState<boolean>();
  const [contractIndex, setContractIndex] = useState(-1);

  const [currentTabKey, setCurrentTabKey] = useState(1);
  const [isBlacklisted, setIsBlacklisted] = useState(null);

  const onWriteClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setContractIndex(-1);
    setIsUpdate(true);
  };

  const onUpdateClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>, index: number) => {
    event.preventDefault();
    setContractIndex(index);
    setIsUpdate(true);
  };

  useEffect(() => {
    setStaffId(Number(match.params.id));
    setIsBlacklisted(null);
    dispatch(doGetTutor2(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  const onTabsChange = (key: string) => {
    switch (key) {
      case '1':
        dispatch(doGetTutor2(staffId));
        setCurrentTabKey(1);
        break;
      case '2':
        dispatch(tutorScheduleMasterList(staffId));
        setCurrentTabKey(2);
        break;
      case '4':
        const query: TutorScheduleLmsQuery = {};
        query.startDate = dateFormat(new Date());
        dispatch(scheduleLmsList(staffId, query));
        setCurrentTabKey(4);
        break;
      case '5':
        const tutorScheduleLmsHistoryQuery: TutorScheduleLmsHistoryQuery = {};
        tutorScheduleLmsHistoryQuery.endDate = dateFormat(new Date());
        dispatch(scheduleLmsHistoryList(staffId, tutorScheduleLmsHistoryQuery));
        setCurrentTabKey(5);
        break;
      case '9':
        dispatch(doGetContractList(staffId));
        setCurrentTabKey(9);
        break;
      case '10':
        const params: ITutorWorkingLogParam = {
          staffId,
        };
        dispatch(doGetTutorWorkingLogList(params));
        setCurrentTabKey(10);
        break;
    }
  };

  const onSubmit = (data: IContractSubmit) => {
    if (typeof isUpdate !== 'undefined') {
      const formData = new FormData();
      formData.append('startDate', data.startDate);
      formData.append('endDate', data.endDate);
      formData.append('classPerPay', String(data.classPerPay));
      formData.append('staffId', String(staffId));
      if (data.file) {
        formData.append('file', data.file);
      }
      if (data.notes) {
        formData.append('notes', data.notes);
      }
      if (isUpdate && contractIndex !== -1) {
        dispatch(doPatchContract(contractList[contractIndex].id, formData));
      } else {
        dispatch(doPostContract(formData));
      }
      setIsUpdate(undefined);
    }
  };

  useEffect(() => {
    if (contractError && contractError.response) {
      switch (contractError.response.status) {
        case 409:
          message.warning('계약기간이 중복됬습니다.');
          break;
      }
    }
  }, [contractError]);

  const onCancelClick = () => {
    setIsUpdate(undefined);
  };

  const onHandleVirtualLogin = () => {
    if (!tutor) {
      message.warn('로그인할 튜터정보가 존재하지 않습니다.');
      return;
    }
    getStaffVirtualLogin(tutor.email).then((response)=>{
      message.success('가상로그인 성공');
    }).catch((e)=>{
      message.warning('가상로그인 하는데 문제가 발생했습니다.')
    })
  }

  const onUpdateTutorInfo = () => {
    if (!tutor) {
      message.warn('업데이트할 튜터정보가 존재하지 않습니다.');
      return;
    }
    dispatch(doUpdateTutor2(staffId, { isBlacklisted: isBlacklisted === 'Y' }))
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='/tutor'>강사관리</Link></Breadcrumb.Item>
      </Breadcrumb>
      <Tabs defaultActiveKey='1' onChange={onTabsChange}>
        <TabPane tab='상세정보' key='1'>
          {
            tutor2IsLoading ? (
              <Loader />
            ) : (
              <TutorInfo
                tutor={tutor}
                isBlacklisted={isBlacklisted}
                setIsBlacklisted={setIsBlacklisted}
              />
            )
          }
        </TabPane>
        <TabPane tab='일정 Master' key='2'>
          {
            tutorScheduleMasterIsLoading || !tutorScheduleMasters ? (
              <Loader />
            ) : (
              <TutorScheduleMaster tutorScheduleMasters={tutorScheduleMasters} />
            )
          }
        </TabPane>
        <TabPane tab='일정상세' key='3'>
          <TutorScheduleDetail timeZone={tutor?.timeZone ? tutor.timeZone : 'Asia/Seoul'} />
        </TabPane>
        <TabPane tab='1:1문의' key='7'>
          {
            boardIsLoading ? (
              <Loader />
            ) : (
              <TutorBoard boardList={boards} />
            )
          }
        </TabPane>
        <TabPane tab='계약정보관리' key='9'>
          {
            contractIsLoading ? (
              <Loader />
            ) : typeof isUpdate === 'undefined' ? (
              <TutorContractTable contractList={contractList} onClick={onWriteClick} onUpdateClick={onUpdateClick} />
            ) : (
              <TutorContractEditor contract={contractList[contractIndex]} onSubmit={onSubmit}
                                   onCancelClick={onCancelClick} />
            )
          }
        </TabPane>
        <TabPane tab='Working Log' key='10'>
          {
            tutorWorkingLogIsLoading ? (<Loader />) : <TutorWorkingLogTable tutorWorkingLogList={tutorWorkingLogList} />
          }
        </TabPane>
      </Tabs>
      <div style={{
        ...divStyle,
        marginTop: '1.5rem'
      }}>
        <Button type='primary' onClick={onHandleVirtualLogin}>가상로그인</Button>
        {
          currentTabKey === 1 &&
            <Button
              type='default'
              style={{ marginLeft: '10px' }}
              onClick={onUpdateTutorInfo}
            >
              정보수정
            </Button>
        }

      </div>
    </>
  );
};

export default TutorPage;
