import React, {FC} from "react";
import DaumPostcode from "react-daum-postcode";
import {Button, Modal} from "antd";
import {IDaumAddressData} from "../models/DaumAddress";

interface IProps {
  visible: boolean;

  addr1(data: string): void;

  addrNum(data: string): void;

  onClose(): void;
}

const DaumAddressModal: FC<IProps> = ({visible, addr1, addrNum, onClose}) => {

  const searchComplete = (data: IDaumAddressData) => {
    addr1(data.address);
    addrNum(data.zonecode);
    onClose();
  };

  return (
    <>
      <Modal
        centered
        title="주소 검색"
        width={800}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>닫기</Button>,
        ]}>
        <DaumPostcode onComplete={searchComplete} autoClose={false}/>
      </Modal>
    </>
  );
};

export default DaumAddressModal;
