import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';

import { User } from '../models/User';
import { ColumnsType } from 'antd/lib/table';
import { LinkStyle, tableStyle } from '../style/antdCssProperty';
import { UserOrigin } from '../models/Enum';
import {format} from "date-fns";

interface IProps {
  users: User[];
}

const UserList: FC<IProps> = ({ users }) => {
  const columns: ColumnsType<User> = [
    {
      title: 'NO',
      dataIndex: 'id',
    },
    {
      title: '가입일',
      key: 'createdAt',
      render: (record: User) => (
          format(new Date(record.createdAt), "yyyy-MM-dd\nHH:mm")
      ),
    },
    {
      title: '이름',
      render: (record: User) => {
        return <Link to={`customer/${record.id}`} style={LinkStyle}>{record.name}</Link>;
      },
    },
    {
      title: '이메일',
      dataIndex: 'email',
    },
    {
      title: '가입경로',
      render: (value, record) => record.origin === UserOrigin.K ? '킨더' : '초등',
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
    },
    {
      title: '자녀명',
      render: (value, record) => (
        record.studentName
          ? <>
            {record.studentName.split(',').map((name, index) => {
              return <span style={{display: "block"}} key={index}>{name}</span>
            })}
          </>
          : '-'
      ),
    },
    {
      title: '자녀 닉네임',
      render: (value, record) => (
          record.studentName
            ? <>
              {record.studentName.split(',').map((name, index) => {
                return <span style={{display: "block"}} key={index}>{name}</span>
              })}
            </>
            : '-'
      ),
    },
    {
      title: '상태',
      render: (value, record) => (
        record.subscriptionState
          ? <>
            {record.subscriptionState.split(',').map((classState, index) => {
              return <span style={{display: "block"}} key={index}>{classState}</span>
            })}
          </>
          : '-'
      ),
    },
  ];

  return (
    <Table
      dataSource={users}
      columns={columns}
      size='small'
      pagination={false}
      rowKey={(record) => `${record.id}`}
      style={tableStyle}
    />
  );
};

export default UserList;
