import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IStudentUserSubscriptionByUser, IStudentUserUnsubscription } from '../../models/UserSubscription';
import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';

export type StudentUserSubscription = IStudentUserSubscriptionByUser & IStudentUserUnsubscription;

interface IProps {
  data: StudentUserSubscription[];
  deactive: boolean | null;

  showModal(record: IStudentUserSubscriptionByUser): void;
}

const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const ReasonModal = styled.div`
  width: 100%;

  table {
    width: 90%;
    margin: auto;

    th, td {
      border: 1px solid gainsboro;
      padding: 10px;
    }

    th {
      width: 30%;
      text-align: center;
      background-color: gainsboro;
    }

  }
`

const SubscriptionTable: FC<IProps> = ({ data, deactive, showModal }) => {
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [modalContent, setModalContent] = useState<IStudentUserSubscriptionByUser | null>(null);

  const onOpenReasonModal = (record: IStudentUserSubscriptionByUser) => {
    setModalContent(record);
    setShowReasonModal(true);
  }

  const onClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>, record: IStudentUserSubscriptionByUser) => {
    event.preventDefault();
    showModal(record);
  };

  const columns: ColumnsType<IStudentUserSubscriptionByUser> = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: '수업구분',
      dataIndex: 'productName',
      render: (value, record) => (
        record.product.name
      ),
    },
    {
      title: '과정',
      dataIndex: 'courseName',
      render: (value, record) => (
        record.course.name
      ),
    },
    {
      title: '고객',
      dataIndex: 'userName',
      render: (value, record) => (
        <Link to={`/customer/${record.student.user.id}`}
              style={LinkStyle}>{record.student.user.name} ({record.student.user.id})</Link>
      ),
    },
    {
      title: '전화번호',
      render: (value, record) => record.student.user.phone,
    },
    {
      title: '학생',
      dataIndex: 'studentName',
      render: (value, record) => (
        `${record.student.name} (${record.student.id})`
      ),
    },
    {
      title: '이메일',
      dataIndex: 'userEmail',
      render: (value, record) => (
        `${record.student.user.email}`
      ),
    },
    {
      title: '상태',
      dataIndex: 'isActive',
      render: (value, record) => (
        <>
          {record.isCanceled ? '해지' : '구독중'}
          {
            record.isCanceled &&
              <>
                <br />
                {
                  record.billkey?.cancelDateTime
                    ? format(new Date(record.billkey.cancelDateTime), 'yyyy-MM-dd HH:mm:ss')
                    : ''
                }
              </>
          }
        </>
      ),
    },
    {
      title: deactive ? '신청일' : '',
      render: (value, record) => (
        deactive
          ? format(new Date(record.createdAt), 'yyyy-MM-dd HH:mm:ss')
          : ''
      ),
    },
    {
      title: deactive ? '구독해지일' : '신청일',
      dataIndex: 'createdAt',
      render: (value, record) => (
        typeof record.unsubscription === 'undefined'
          ? format(new Date(record.createdAt), 'yyyy-MM-dd HH:mm:ss')
          : (
              <Underline onClick={() => onOpenReasonModal(record)}>
                {format(new Date(record.unsubscription.createdAt), 'yyyy-MM-dd HH:mm:ss')}
              </Underline>
            )
      ),
    },
    {
      title: deactive ? '종료일' : '',
      dataIndex: 'createdAt',
      render: (value, record) => (
        record.userTransaction
          ? format(new Date(record.userTransaction.endDate), 'yyyy-MM-dd')
          : ''
      ),
    },
    {
      title: '',
      render: (value, record) => (
        record.isCanceled ? '' : <p onClick={e => onClick(e, record)} style={{ cursor: 'pointer' }}>취소</p>
      ),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={data}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
      <Modal
        title="해지사유"
        visible={showReasonModal}
        onCancel={() => setShowReasonModal(false)}
        footer={[
          <Button
            type="primary"
            onClick={() => setShowReasonModal(false)}
            style={{ width: '150px' }}
          >
            확인
          </Button>
        ]}
      >
        <ReasonModal>
          <table>
            <tbody>
              <tr>
                <th>학생</th>
                <td>{modalContent && modalContent.student.name} ({modalContent && modalContent.student.id})</td>
              </tr>
              <tr>
                <th>해지신청일</th>
                <td>{modalContent && format(new Date(modalContent.unsubscription.createdAt), 'yyyy-MM-dd HH:mm:ss')}</td>
              </tr>
              <tr>
                <th>해지사유</th>
                <td>{modalContent && modalContent.unsubscription.unsubscriptionReason}</td>
              </tr>
              <tr>
                <th>상세사유</th>
                <td>{modalContent && modalContent.unsubscription.reasonDetail}</td>
              </tr>
            </tbody>
          </table>
        </ReasonModal>
      </Modal>
    </>
  );
};

export default SubscriptionTable;
