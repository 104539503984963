import { BookItem } from '../models/Book';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { list, read } from '../api/book';
import { messageAdd, showMessage } from './message.slice';

interface IBookState {
  books: BookItem[] | null;
  book: BookItem | null;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBookState = {
  books: null,
  book: null,
  isLoading: false,
  error: null,
};

const bookSlicer = createSlice({
  name: 'book',
  initialState,
  reducers: {
    requestStart: (state: IBookState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: IBookState, action: PayloadAction<BookItem[]>) => {
      state.books = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IBookState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    requestReadSuccess: (state: IBookState, action: PayloadAction<BookItem>) => {
      state.book = action.payload;
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestReadSuccess,
  requestFailure,
} = bookSlicer.actions;

export default bookSlicer.reducer;

export const bookList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list();
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === 404) {
      dispatch(messageAdd(showMessage('error', '책이 존재하지 않습니다.')));
    }
    dispatch(requestFailure(e));
  }
};

export const bookRead = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await read(id);
    dispatch(requestReadSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === 404) {
      dispatch(messageAdd(showMessage('error', '책이 존재하지 않습니다.')));
    }
    dispatch(requestFailure(e));
  }
};