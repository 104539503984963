import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Button, Input, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {messageAdd, showMessage} from "../../store/message.slice";
import DaumAddressModal from "../DaumAddressModal";
import {orderAddressChange} from "../../store/booksOrder.slice";
import {RootState} from "../../store/rootReducer";

interface IProps {
  visible: boolean;
  userId: number;
  boNo: number;
  bocNo: number;

  onCancel(): void;

  onChange(): void;
}

const BooksOrderAddressChangeModal: FC<IProps> = ({visible, userId, boNo, bocNo, onCancel, onChange}) => {
  const dispatch = useDispatch();
  const {user} = useSelector((state: RootState) => state.authState);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [addr1, setAddr1] = useState('');
  const [addr2, setAddr2] = useState('');
  const [addrNum, setAddrNum] = useState('');
  const [addressSearchModalVisible, setAddressSearchModalVisible] = useState(false);

  const onAddr2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddr2(event.target.value);
  };

  const saveBtn = async () => {
    if (!addr1 || !addr2 || !addrNum) {
      dispatch(messageAdd(showMessage('error', '주소를 입력해주세요.')));
      return;
    }

    setSaveBtnLoading(true);

    await dispatch(orderAddressChange({
      addr1: addr1,
      addr2: addr2,
      addrNo: addrNum,
      implementer: user ? `${user.lastName}${user.firstName}` : 'unknown',
      implementerSite: "lms",
      userId: userId,
      boNo: boNo,
      bocNo: bocNo,
    }));

    onChange();

    setSaveBtnLoading(false);

    onCancel();
  };

  const addressSearchModalShow = (visible: boolean) => {
    setAddressSearchModalVisible(visible);
  };

  return (
    <>
      <Modal
        centered
        visible={visible}
        width={1000}
        onCancel={onCancel}
        footer={[
          <Button type="primary" key="search" onClick={() => {
            addressSearchModalShow(true)
          }}>주소 검색</Button>,
          <Button key="back" onClick={onCancel}>닫기</Button>,
          <Button type="primary" danger key="save" onClick={saveBtn} loading={saveBtnLoading}>저장</Button>,
        ]}
        title="구독 주문 주소 변경">
        <div>
          우편번호 : <Input type="text" disabled={true} value={addrNum}/>
          주소 : <Input type="text" disabled={true} value={addr1}/>
          상세주소 : <Input type="text" onChange={onAddr2Change}/>
        </div>
        <DaumAddressModal
          addr1={setAddr1}
          addrNum={setAddrNum}
          visible={addressSearchModalVisible}
          onClose={() => {
            addressSearchModalShow(false)
          }}/>
      </Modal>
    </>
  );
};

export default BooksOrderAddressChangeModal;
