import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth.slice';
import boardReducer from './board.slice';
import commentReducer from './comment.slice';
import messageReducer from './message.slice';
import userSubscriptionReducer from './userSubscription.slice';
import userUnsubscriptionReducer from './userUnsubscription.slice';
import userReducer from './user.slice';
import studentReducer from './student.slice';
import bookReducer from './book.slice';
import episodeReducer from './episode.slice';
import episodePageReducer from './episodePage.slice';
import staffReducer from './staff.slice';
import tutorScheduleMasterReducer from './tutorScheduleMaster.slice';
import scheduleReducer from './schedule.slice';
import couponReducer from './coupon.slice';
import productReducer from './product.slice';
import studyRoomVideoReducer from './studyRoomVideo.slice';
import issueReducer from './issue.slice';
import courseReducer from './course.slice';
import userTransactionReducer from './userTransaction.slice';
import tutor2Reducer from './tutor2.slice';
import calenderReducer from './calender.slice';
import contractReducer from './contract.slice';
import tutorWorkingLogReducer from './tutorWorkingLog.slice';
import studyroomReducer from './studyroom.slice';
import evaluationReducer from './evaluation.slice';
import feedbackReducer from './feedback.slice';
import studyCourseReducer from './studentCourse.slice';
import schedule2Reducer from './schedule2.slice';
import scheduleCancelLogReducer from './scheduleCancelLog.slice';
import levelTestReducer from './levelTest.slice';
import monitoringReducer from './monitoring.slice';
import paymentReducer from './payment.slice';
import messageLogReducer from './messageLog.slice';
import levelTestApplicantReducer from './levelTestApplicant.slice';
import brotherApplyReducer from "./brotherApply.slice";
import brotherApplyDetailSlice from "./brotherApplyDetail.slice";
import booksSingleOrderSlice from "./booksSingleOrder.slice";
import booksOrderLogSlice from "./booksOrderLog.slice";
import booksSubscribeOrderSlice from "./booksSubscribeOrder.slice";
import booksOrderSlice from "./booksOrder.slice";

const rootReducer = combineReducers({
  authState: authReducer,
  boardState: boardReducer,
  commentState: commentReducer,
  messageState: messageReducer,
  userSubscriptionState: userSubscriptionReducer,
  userUnsubscriptionState: userUnsubscriptionReducer,
  userState: userReducer,
  studentState: studentReducer,
  bookState: bookReducer,
  episodeState: episodeReducer,
  episodePageState: episodePageReducer,
  staffState: staffReducer,
  tutorScheduleMasterState: tutorScheduleMasterReducer,
  scheduleState: scheduleReducer,
  couponState: couponReducer,
  productState: productReducer,
  studyRoomVideoState: studyRoomVideoReducer,
  issueState: issueReducer,
  courseState: courseReducer,
  userTransactionState: userTransactionReducer,
  tutor2State: tutor2Reducer,
  calenderState: calenderReducer,
  contractState: contractReducer,
  tutorWorkingLogState: tutorWorkingLogReducer,
  studyroomState: studyroomReducer,
  evaluationState: evaluationReducer,
  feedbackState: feedbackReducer,
  studyCourseState: studyCourseReducer,
  schedule2State: schedule2Reducer,
  scheduleCancelLogState: scheduleCancelLogReducer,
  levelTestState: levelTestReducer,
  monitoringState: monitoringReducer,
  paymentState: paymentReducer,
  messageLogState: messageLogReducer,
  levelTestApplicantState: levelTestApplicantReducer,
  brotherApplyState: brotherApplyReducer,
  brotherApplyDetailState: brotherApplyDetailSlice,
  booksSingleOrderState: booksSingleOrderSlice,
  booksOrderLogState: booksOrderLogSlice,
  booksSubscribeOrderState: booksSubscribeOrderSlice,
  booksOrderState: booksOrderSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
