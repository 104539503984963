import React, {FC, useState} from "react";
import {BooksSubscribeOrderMonthCancelPossibleStateList, IGetBooksOrderDetailInfoData} from "../../models/BooksOrder";
import {Button, Table, Modal, Popconfirm} from "antd";
import {tableStyle} from "../../style/antdCssProperty";
import {BooksSubscribeOrderSearchStateList} from "../../models/BooksSubscribeOrder";
import {format} from "date-fns";
import {ColumnsType} from "antd/lib/table";
import {useDispatch} from "react-redux";
import {setSubscribeOrderMonthCancel} from "../../store/booksSubscribeOrder.slice";
import {FileSyncOutlined} from "@ant-design/icons";

interface IProps {
  visible: boolean;
  orderInfoData: IGetBooksOrderDetailInfoData[];
  orderUserId: number;

  onCancel(): void;

  onChange(boNo: number, userId: number): void;

  orderAddressChangeModalShow(boNo: number, bocNo: number, userId: number, view: boolean): void;
}

/**
 * 구독 주문 상세 정보 Modal
 */
const BooksOrderDetailInfoModal: FC<IProps> = ({
                                                 visible,
                                                 orderInfoData,
                                                 orderUserId,
                                                 onCancel,
                                                 onChange,
                                                 orderAddressChangeModalShow
                                               }) => {
  const dispatch = useDispatch();
  const [subscribeMonthCancelConfirmLoading, setSubscribeMonthCancelConfirmLoading] = useState(false);

  const subscribeMonthCancel = async (userId: number, boNo: number, bocNo: number) => {
    setSubscribeMonthCancelConfirmLoading(true);
    await dispatch(setSubscribeOrderMonthCancel({bocNo: bocNo, boNo: boNo, userId: userId}));
    setSubscribeMonthCancelConfirmLoading(false);
    onChange(boNo, userId);
  };

  const columns: ColumnsType<IGetBooksOrderDetailInfoData> = [
    {
      title: '상세 주문 번호',
      dataIndex: 'no',
      fixed: 'left',
      width: 150,
    },
    {
      title: '결제일',
      render: (record: IGetBooksOrderDetailInfoData) => {
        return format(new Date(record.createdAt), 'yyyy-MM-dd kk:mm:ss');
      },
    },
    {
      title: '구독 개월 차수',
      dataIndex: 'subscribeMonth',
    },
    {
      title: '주문 상태',
      render: (record: IGetBooksOrderDetailInfoData) => {
        return Object.entries(BooksSubscribeOrderSearchStateList).map((item) => {
          return String(record.state) === item[0] ? item[1] : '';
        });
      },
    },
    {
      title: '카드사 승인 번호',
      dataIndex: 'applyNo',
    },
    {
      title: '결제 금액',
      render: (record: IGetBooksOrderDetailInfoData) => {
        return record.price.toLocaleString('ko-kr');
      },
    },
    {
      title: '주소',
      width: 300,
      render: (record: IGetBooksOrderDetailInfoData) => {
        return (
          <>
            {record.addrNum}<br/>
            {record.addr1} {record.addr2}
          </>
        )
      },
    },
    {
      title: '출고일',
      render: (record: IGetBooksOrderDetailInfoData) => {
        return record.invoiceDate ? format(new Date(record.invoiceDate), 'yyyy-MM-dd kk:mm:ss') : '-';
      },
    },
    {
      title: '송장번호',
      dataIndex: 'invoiceNum',
    },
    {
      title: '택배사 명',
      dataIndex: 'invoiceCompany',
    },
    {
      title: '관리',
      fixed: 'right',
      width: 150,
      render: (record: IGetBooksOrderDetailInfoData) => {
        const cancelPossible: boolean = (BooksSubscribeOrderMonthCancelPossibleStateList.indexOf(record.state) === -1);
        return (
          <>
            <Popconfirm
              key="subscribeMonthCancelConfirm"
              title="해당 주문을 취소 처리하시겠습니까?(해당 결제 환불 및 구독 취소 처리됩니다.)"
              onConfirm={() => {
                subscribeMonthCancel(orderUserId, record.booksOrderNo, record.no).then();
              }}
              okText="확인"
              cancelText="닫기"
              disabled={cancelPossible}>
              <Button
                type='primary' danger
                loading={subscribeMonthCancelConfirmLoading}
                disabled={cancelPossible}>
                결제 취소
              </Button>
            </Popconfirm>
            <Button
              style={{marginTop: '5px'}}
              type='primary'
              onClick={() => orderAddressChangeModalShow(record.booksOrderNo, record.no, orderUserId, true)}
              icon={<FileSyncOutlined/>}>
              주소 변경
            </Button>
          </>
        );
      },
    }
  ];

  return (
    <>
      <Modal title="주문 상세 보기"
             centered
             visible={visible}
             width={1600}
             onCancel={onCancel}
             footer={[
               <Button key="back" onClick={onCancel}>닫기</Button>,
             ]}>
        <Table style={tableStyle}
               dataSource={orderInfoData}
               columns={columns}
               scroll={{x: 2000}}
               size='small'
               rowKey={(record) => `${record.no}`}
               pagination={false}/>
      </Modal>
    </>
  );
};

export default BooksOrderDetailInfoModal;
