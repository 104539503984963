import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import MainRoute from './main.route';
import LoginRoute from './login.route';
import MonitoringRoute from './monitoring.route';

const RootRouter: FC<RouteComponentProps> = () => {
  const { user } = useSelector((state: RootState) => state.authState);
  const history = useHistory();

  return user ? history.location.pathname === '/monitoring' ? <MonitoringRoute/> : <MainRoute /> : <LoginRoute />;
};

export default withRouter(RootRouter);
