import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import {Breadcrumb, Col, Input, Modal, PageHeader, Pagination, Radio, Row} from "antd";
import {Link} from "react-router-dom";
import CustomerBrotherApplayFilterBox from "../components/CustomerBrotherApply/CustomerBrotherApplayFilterBox";
import {BrotherApplyParams} from "../models/BrotherApply";
import {brotherApplyList} from "../store/brotherApply.slice";
import BrotherApplyListTable from "../components/BrotherApplyListTable";
import {getSession, setSession} from "../libs/session";
import BrotherApplyModal from "../components/CustomerBrotherApply/BrotherApplyModal";
import {brotherApplyDetailInfo} from "../store/brotherApplyDetail.slice";

const CustomerBrotherApplyPage: FC = () => {
  const dispatch = useDispatch();
  const {applyList, total} = useSelector((state: RootState) => state.brotherApplyState);
  const {detailInfo} = useSelector((state: RootState) => state.brotherApplyDetailState);
  const [current, setCurrent] = useState(1);
  const [param, setParam] = useState<BrotherApplyParams>();
  const [applyModalVisible, setApplyModalVisible] = useState(false);

  const onSubmit = (param: BrotherApplyParams): void => {
    setCurrent(1);
    param.page = 1;
    setParam(param);
    setSession('customerBrotherApply', param);
    dispatch(brotherApplyList(param));
  };

  const onChange = (page: number) => {
    const newParams: BrotherApplyParams = {
      ...param,
      page,
    };
    setSession('customerBrotherApply', newParams);
    setCurrent(page);
  };

  const applyModalShow = async (applyNo: number, view: boolean) => {
    setApplyModalVisible(view);
    await dispatch(brotherApplyDetailInfo(applyNo));
  };

  const applyModalClose = () => {
    setApplyModalVisible(false);
  };

  useEffect(() => {
    const sessionParams = getSession<BrotherApplyParams>('customerBrotherApply');
    if (sessionParams) {
      if (sessionParams.page) {
        setCurrent(sessionParams.page);
      }
      dispatch(brotherApplyList(sessionParams));
    } else {
      const data: BrotherApplyParams = {
        ...param,
        page: current,
      };
      dispatch(brotherApplyList(data));
    }
  }, [dispatch, current]);

  useEffect(() => {
    if (param) {
      setSession('customerBrotherApply', param);
    }
  }, [param]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>형제회원신청</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='형제회원신청'>
      </PageHeader>
      <CustomerBrotherApplayFilterBox onSubmit={onSubmit}/>
      <span>total: {total}</span>
      <Row gutter={10}>
        <Col span={24}>
          <BrotherApplyListTable applyList={applyList ? applyList : []} applyModalShow={applyModalShow}/>
        </Col>
      </Row>
      <Pagination
        current={current}
        onChange={onChange}
        defaultPageSize={20}
        total={total}
        style={{marginTop: '1.5rem'}}
        showSizeChanger={false}
      />
      <BrotherApplyModal
        visible={applyModalVisible}
        applyDetailInfoData={detailInfo}
        onCancel={applyModalClose}>
      </BrotherApplyModal>
    </>
  );
};

export default CustomerBrotherApplyPage;
