import {BrotherApplyList} from "../models/BrotherApply";
import React, {FC} from "react";
import {ColumnsType} from "antd/lib/table";
import {LinkStyle, tableStyle} from "../style/antdCssProperty";
import {Button, Table} from 'antd';
import {format} from "date-fns";
import {Link} from "react-router-dom";

interface IProps {
  applyList: BrotherApplyList[];
  applyModalShow(applyNo: number, view: boolean): void;
}

const BrotherApplyListTable: FC<IProps> = ({applyList, applyModalShow}) => {
  const columns: ColumnsType<BrotherApplyList> = [
    {
      title: 'NO',
      dataIndex: 'ba_no',
    },
    {
      title: '신청일',
      render: (record: BrotherApplyList) => {
        const date = new Date(record.ba_createdAt);
        return format(date, 'yyyy-MM-dd kk:mm:ss');
      }
    },
    {
      title: '이메일',
      render: (record: BrotherApplyList) => {
        return record.u_email;
      }
    },
    {
      title: '부모명',
      render: (record: BrotherApplyList) => {
        return <Link to={`customer/${record.u_id}`} style={LinkStyle}>{record.u_name}</Link>;
      }
    },
    {
      title: '기 회원명',
      render: (record: BrotherApplyList) => {
        return record.ba_originalMemberName;
      }
    },
    {
      title: '동생 회원명',
      render: (record: BrotherApplyList) => {
        return record.ba_applyBrotherName;
      }
    },
    {
      title: '연락처',
      dataIndex: 'u_phone',
    },
    {
      title: '처리일',
      render: (record: BrotherApplyList) => {
        if (!record.ba_processingDate) {
          return '-';
        }
        const date = new Date(record.ba_processingDate);
        return format(date, 'yyyy-MM-dd kk:mm:ss');
      }
    },
    {
      title: '처리자',
      render: (record: BrotherApplyList) => {
        return record.ba_enforcer ? record.ba_enforcer : '-';
      }
    },
    {
      title: '처리 상태',
      render: (record: BrotherApplyList) => {
        return (
          <Button type={record.ba_state === 'apply' ? 'primary' : 'default'}
                  onClick={() => applyModalShow(record.ba_no, true)}>
            {
              record.ba_state === 'apply' ? '신청' :
                record.ba_state === 'reject' ? '처리불가' :
                  record.ba_state === 'success' ? '완료' :
                    '-'
            }
          </Button>
        );
      }
    },
  ];

  return (
    <Table
      dataSource={applyList}
      columns={columns}
      size='small'
      pagination={false}
      rowKey={(record) => `${record.ba_no}`}
      style={tableStyle}>
    </Table>
  );
}

export default BrotherApplyListTable;
