/**
 * 단품 주문 검색 Params
 */
export interface IBooksSingleOrderParams {
  page?: number;
  searchDateS?: string;
  searchDateE?: string;
  searchKey?: string;
  searchText?: string;
  searchState?: string;
  excelDownUse?: boolean;
}

/**
 * 단품 주문 검색 정보 리스트
 */
export interface IBooksSingleOrderDataList {
  bo_no: number;
  bo_state: number;
  bo_paymethod: number;
  bo_isAutoPay: number;
  bo_cancelRequestDate: string;
  bo_cancelDate: string;
  bo_goodsName: string;
  bo_name: string;
  bo_email: string;
  bo_phone: string;
  bo_addr1: string;
  bo_addr2: string;
  bo_addrNum: string;
  bo_orderGoodsType: number;
  bo_cancelSubscribeDate: string;
  bo_createdAt: string;
  bo_updatedAt: string;
  bo_deletedAt: string;
  bo_userId: number,
  boc_applyNo: string;
  boc_price: number,
  boc_invoiceNum: string;
  boc_invoiceCompany: string;
  u_id: number;
  boc_no: number;
  bo_studentId: number;
  s_name: string;
}

/**
 * 단품 주문 검색 API Response
 */
export interface IBooksSingleOrderDataListResponse {
  dataList: IBooksSingleOrderDataList[];
  total: number;
}

/**
 * 단품 주문 키워드 검색 List
 */
export const BooksSingleOrderSearchKeyList = {
  phone: '전화번호',
  name: '이름',
  email: '아이디',
  invoiceNum: '운송장번호',
  studentName: '자녀명',
}

/**
 * 단품 주문 상태 검색 List
 */
export const BooksSingleOrderSearchStateList = {
  1: '결제 완료',
  2: '출고',
  4: '취소접수',
  5: '취소완료',
  6: '취소기각',
  7: '취소가능',
}

/**
 * 단품 주문 취소 완료 처리 API Params
 */
export interface ICancelSingleOrderParams {
  implementer: string;
  booksOrderNo: number;
  booksOrderComponentsNo: number;
  implementerSite: string;
}

/**
 * 단품 주문 취소 완료 처리 API Response
 */
export interface ICancelSingleOrderResponse {
  result: boolean;
  resultMessage: string;
}

export interface IBooksSingleOrderExcelData {
  bo_no: number;
  bo_state: string;
  bo_paymethod: number;
  bo_isAutoPay: number;
  bo_cancelRequestDate: string;
  bo_cancelDate: string;
  bo_goodsName: string;
  bo_name: string;
  bo_email: string;
  bo_phone: string;
  bo_addr1: string;
  bo_addr2: string;
  bo_addrNum: string;
  bo_orderGoodsType: number;
  bo_cancelSubscribeDate: string;
  bo_createdAt: string;
  bo_updatedAt: string;
  bo_deletedAt: string;
  bo_userId: number,
  boc_applyNo: string;
  boc_price: number,
  boc_invoiceNum: string;
  boc_invoiceCompany: string;
  u_id: number;
  boc_no: number;
  s_name: string;
  bo_studentId: number;
}
