import {
  IBooksSingleOrderDataList,
  IBooksSingleOrderParams, ICancelSingleOrderParams, ICancelSingleOrderResponse
} from "../models/BooksSingleOrder";
import axios, {AxiosPromise} from "axios";
import qs from "qs";
import {IBooksSubscribeOrderDataList, IBooksSubscribeOrderParams} from "../models/BooksSubscribeOrder";
import {
  IGetBooksOrderDetailInfoData,
  IOrderCancelSubscribe, ISetOrderAddressChangeParams,
  ISubscribeOrderMonthCancelParams, ISubscribeOrderMonthCancelResponse
} from "../models/BooksOrder";

/**
 * 단품 주문 검색 API
 * @param params IBooksSingleOrderParams
 * @return {} IBooksSingleOrderDataList
 */
export const singleOrderDataSearchList = (params?: IBooksSingleOrderParams): AxiosPromise<IBooksSingleOrderDataList[]> => {
  let url = `/api/order/books/staff/singleOrder/list`;
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

/**
 * 단품 주문 취소 완료 처리 API
 * @param params ICancelSingleOrderParams
 * @return {} ICancelSingleOrderResponse
 */
export const requestCancelSingleOrder = (params: ICancelSingleOrderParams): AxiosPromise<ICancelSingleOrderResponse> => {
  return axios.put('/api/order/books/cancelSingleOrder', params);
};

/**
 * 구독 주문 검색 API
 * @param params IBooksSubscribeOrderParams
 * @return {} IBooksSubscribeOrderDataList
 */
export const subscribeOrderDataSearchList = (params?: IBooksSubscribeOrderParams): AxiosPromise<IBooksSubscribeOrderDataList[]> => {
  let url = `/api/order/books/staff/subscribeOrder/list`;
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

/**
 * 해당 주문 번호 상세 주문 정보 가져오기
 * @param boNo 주문 번호
 * @return {} IGetBooksOrderDetailInfoData
 */
export const getOrderDetailInfoData = (boNo: number): AxiosPromise<IGetBooksOrderDetailInfoData[]> => {
  return axios.get(`/api/order/books/staff/orderDetailInfo/${boNo}`);
}

/**
 * 해당 회원 구독 주문 구독 취소 처리로 변경
 * @param userId 회원 번호
 * @param boNo 주문 번호
 * @return {} IOrderCancelSubscribe
 */
export const orderCancelSubscribe = (userId: number): AxiosPromise<IOrderCancelSubscribe> => {
  return axios.put('/api/order/books/staff/cancelSubscribe', {userId: userId});
}

/**
 * 해당 회원 구독 주문 월 결제 취소 처리
 * @param params ISubscribeOrderMonthCancelParams
 * @return {} ISubscribeOrderMonthCancelResponse
 */
export const subscribeOrderMonthCancel = (params: ISubscribeOrderMonthCancelParams): AxiosPromise<ISubscribeOrderMonthCancelResponse> => {
  return axios.put('/api/order/books/staff/subscribeOrderMonthRefund', params);
};

/**
 * 주문자 배송 정보 변경 처리
 * @param params ISetOrderAddressChangeParams
 * @return 204
 */
export const setOrderAddressChange = (params: ISetOrderAddressChangeParams): AxiosPromise => {
  return axios.put('/api/order/books/orderAddressChange', params);
};
