import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ColumnsType } from 'antd/lib/table';
import { IPaymentSchedule, ITierBonusDetail, ITierBonusResult } from '../../models/payment.model';
import { Table } from 'antd';
import { tableStyle } from '../../style/antdCssProperty';
import { ScheduleType } from '../../models/Enum';
import { getBonus } from '../../libs/helper';
import { setCreditTotal } from '../../store/payment.slice';

const TierPaymentTable: FC = () => {
  const dispatch = useDispatch();

  const { tierBonus, paymentSchedule } = useSelector((root: RootState) => root.paymentState);

  const [tierBonusResultList, setTierBonusResultList] = useState<ITierBonusResult[]>([]);
  const [tierBonusDetailList, setTierBonusDetailList] = useState<ITierBonusDetail[]>([]);

  useEffect(() => {
    const filterSchedule = paymentSchedule.filter((item) => item.scheduleType === ScheduleType.SUCCESS);
    if (tierBonus && filterSchedule.length >= 41) {
      const bonus = getBonus(tierBonus, filterSchedule.length)
      setTierBonusResultList(bonus);
      setTierBonusDetailList([...tierBonus.tierBonusDetails].reverse());
    } else {
      setTierBonusResultList([]);
      setTierBonusDetailList([]);
    }
  }, [tierBonus, paymentSchedule]);

  useEffect(() => {
    let sum = 0;
    for (const item of tierBonusResultList) {
      sum += item.totalCredit;
    }
    dispatch(setCreditTotal({ tierCredit: sum }));
  }, [dispatch, tierBonusResultList]);

  const columns: ColumnsType<ITierBonusDetail> = [
    {
      title: '보너스 구간',
      key: 'bonusRange',
      render: (value, record) => `${record.bonusMin}~${record.bonusMax === null ? '' : record.bonusMax}`,
    },
    {
      title: 'Classes 개수',
      key: 'total',
      render: (value, record, index) => {
        if (tierBonusResultList.length !== 0) {
          return tierBonusResultList[index].total;
        }
        return '';
      },
    },
    {
      title: '구간별 보너스 금액',
      key: 'credit',
      render: (value, record) => `$${record.credit.toFixed(2)}`,
    },
    {
      title: '금액',
      key: 'totalCredit',
      render: (value, record, index) => {
        if (tierBonusResultList.length !== 0) {
          return tierBonusResultList[index].totalCredit;
        }
        return '';
      },
    },
  ];

  const summary = (pageData: ITierBonusDetail[]) => {
    let sum = 0;

    for (const item of tierBonusResultList) {
      sum += item.totalCredit;
    }

    return <Table.Summary.Row>
      <Table.Summary.Cell align='center' index={0} colSpan={3}>계</Table.Summary.Cell>
      <Table.Summary.Cell align='center' index={1} colSpan={1}>${sum.toFixed(2)}</Table.Summary.Cell>
    </Table.Summary.Row>;
  };

  return (
    <Table
      style={{ ...tableStyle, marginTop: '1.5rem' }}
      dataSource={tierBonusDetailList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
      title={() => `Tier Bonus (Tier Number: ${tierBonus?.tier ?? 1})`}
      summary={summary}
    />
  );
};

export default TierPaymentTable;
