import React, {ChangeEvent, FC, useState} from "react";
import {Button, Input, message, Modal, Popconfirm, Radio, Table} from "antd";
import {BrotherApplyDetailInfo} from "../../models/BrotherApply";
import {tableStyle} from "../../style/antdCssProperty";
import {BrotherApplyState} from "../../models/Enum";
import {format} from "date-fns";
import {RadioChangeEvent} from "antd/lib/radio/interface";
import {brotherApplyStateUpdate} from "../../store/brotherApplyDetail.slice";
import {useDispatch} from "react-redux";

const {TextArea} = Input;

interface IProps {
  visible: boolean;
  applyDetailInfoData: BrotherApplyDetailInfo[];

  onCancel(): void;
}

const BrotherApplyModal: FC<IProps> = ({visible, applyDetailInfoData, onCancel}) => {
  const dispatch = useDispatch();
  const [applyState, setApplyState] = useState('');
  const [reason, setReason] = useState('');

  const onChange_ApplyState = (event: RadioChangeEvent) => {
    setApplyState(event.target.value as BrotherApplyState);
  };

  const onChange_Reason = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value);
  };

  const applySaveBtn = async () => {
    if (applyState === BrotherApplyState.REJECT && !reason) {
      message.warning('미승인 사유를 입력해주세요.');
      return;
    } else if (!applyState) {
      message.warning('처리 상태를 선택해주세요.');
      return;
    }

    await dispatch(brotherApplyStateUpdate(
      Number(applyDetailInfoData[0].no),
      {'applyState': applyState, 'reason': reason})
    );
  };


  const applyInfoColumns = [
    {
      title: '형제 회원 신청 정보',
      children: [
        {
          title: '아이디',
          render: (record: BrotherApplyDetailInfo) => {
            return record.user.email;
          }
        },
        {
          title: '회원명',
          render: (record: BrotherApplyDetailInfo) => {
            return record.user.name;
          },
        },
        {
          title: '부모명',
          render: (record: BrotherApplyDetailInfo) => {
            return record.user.name;
          },
        },
        {
          title: '연락처',
          render: (record: BrotherApplyDetailInfo) => {
            return record.user.phone;
          },
        },
        {
          title: '형제회원명',
          render: (record: BrotherApplyDetailInfo) => {
            return record.applyBrotherName;
          },
        },
        {
          title: '생년월일',
          render: (record: BrotherApplyDetailInfo) => {
            return format(new Date(record.applyBrotherDateOfBirth), "yyyy-MM-dd");
          },
        },
      ],
    },
  ];

  const applyManagementColumns = [
    {
      title: '신청 관리',
      children: [
        {
          render: (record: BrotherApplyDetailInfo) => {
            return <>
              <Radio.Group style={{textAlign: 'center', width: '100%'}}
                           onChange={onChange_ApplyState} defaultValue={record.state}>
                <Radio value={BrotherApplyState.SUCCESS} checked={record.state === 'success'}>승인</Radio>
                <Radio value={BrotherApplyState.REJECT} checked={record.state === 'reject'}>미승인</Radio>
              </Radio.Group>
            </>;
          },
        },
        {
          render: (record: BrotherApplyDetailInfo) => {
            return <>
              <div style={{width: '100%', textAlign: 'center'}}>
                사유
              </div>
              <TextArea rows={4}
                        defaultValue={record.reason}
                        style={{width: '100%'}}
                        onChange={onChange_Reason}>
              </TextArea>
            </>;
          }
        }
      ],
    },
  ];

  return (
    <Modal
      title="형제 회원 신청 내역"
      centered
      visible={visible}
      width={950}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          취소
        </Button>,
        <Popconfirm key="submitConfirm" title="저장 하시겠습니까?" onConfirm={applySaveBtn} okText="저장" cancelText="취소">
          <Button key="submit" type={"primary"} loading={false}>
            저장
          </Button>
        </Popconfirm>,
        <Button key="button" type={"primary"}>
          첨부파일 다운로드
        </Button>
      ]}>
      <Table
        style={tableStyle}
        dataSource={applyDetailInfoData}
        columns={applyInfoColumns}
        size='small'
        rowKey={(record) => `${record.no}`}
        pagination={false}/>
      <Table
        style={tableStyle}
        dataSource={applyDetailInfoData}
        columns={applyManagementColumns}
        size='small'
        rowKey={(record) => `${record.no}`}
        pagination={false}/>
    </Modal>
  );
};

export default BrotherApplyModal;
