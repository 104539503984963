import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, PageHeader, Pagination, Row} from "antd";
import {Link} from "react-router-dom";
import BooksSingleOrderFilterBox from "../components/Transaction/BooksSingleOrderFilterBox";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import {
  IBooksSingleOrderExcelData,
  IBooksSingleOrderParams
} from "../models/BooksSingleOrder";
import {getSession, setSession} from "../libs/session";
import BooksSingleOrderListTable from "../components/BooksSingleOrderListTable";
import {booksSingleOrderDataList} from "../store/booksSingleOrder.slice";
import BooksOrderLogModal from "../components/BooksOrder/BooksOrderLogModal";
import {booksOrderLogData} from "../store/booksOrderLog.slice";
import {FileExcelOutlined} from "@ant-design/icons";
import {singleOrderDataSearchList} from "../api/booksOrder";
import {messageAdd, showMessage} from "../store/message.slice";
import {ExcelDown} from "../libs/excelDown";
import {BooksOrderStateList} from "../models/BooksOrder";
import {format} from "date-fns";
import BooksOrderAddressChangeModal from "../components/BooksOrder/BooksOrderAddressChangeModal";

/**
 * [배송] 단품 주문 Page
 * @constructor
 */
const BooksSingleOrderPage: FC = () => {
  const dispatch = useDispatch();
  const {dataList, total} = useSelector((root: RootState) => root.booksSingleOrderState);
  const {logDataList} = useSelector((root: RootState) => root.booksOrderLogState);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState<IBooksSingleOrderParams>();
  const [current, setCurrent] = useState(1);
  const [orderLogModalVisible, setOrderLogModalVisible] = useState(false);
  const [orderAddressChangeModalVisible, setOrderAddressChangeModalVisible] = useState(false);
  const [selectUserId, setSelectUserId] = useState(0);
  const [selectBooksOrderNo, setSelectBooksOrderNo] = useState(0);
  const [selectBooksOrderComponentsNo, setSelectBooksOrderComponentsNo] = useState(0);

  const onSubmit = (params: IBooksSingleOrderParams): void => {
    params.page = 1;
    setPage(params.page);
    setParams(params);
    setSession('booksSingleOrderParams', params);
    current === 1 ? dispatch(booksSingleOrderDataList(params)) : setCurrent(1);
  };

  const onPageChange = (page: number) => {
    const newParams: IBooksSingleOrderParams = {
      ...params,
      page,
    };
    setSession('booksSingleOrderParams', newParams);
    setParams(newParams);
    setCurrent(page);
    setPage(page);
  };

  const orderLogModalClose = () => {
    setOrderLogModalVisible(false);
  };

  const orderLogModalShow = async (boNo: number, view: boolean, bocNo?: number) => {
    setOrderLogModalVisible(view);
    await dispatch(booksOrderLogData({bo_no: boNo}));
  };

  const onPageDataChange = () => {
    dispatch(booksSingleOrderDataList(params));
  };

  const orderAddressChangeModalShow = async (boNo: number, bocNo: number, userId: number, view: boolean) => {
    setSelectUserId(userId);
    setSelectBooksOrderNo(boNo);
    setSelectBooksOrderComponentsNo(bocNo);
    setOrderAddressChangeModalVisible(view);
  };

  const orderAddressChangeModalClose = () => {
    setSelectUserId(0);
    setSelectBooksOrderNo(0);
    setSelectBooksOrderComponentsNo(0);
    setOrderAddressChangeModalVisible(false);
  };

  const onExcelClick = async () => {
    const newParams: IBooksSingleOrderParams = {
      ...params,
      excelDownUse: true,
    };
    const response = await singleOrderDataSearchList(newParams);
    if (response.data.length < 1) {
      dispatch(messageAdd(showMessage('error', '검색된 데이터가 없습니다.')));
      return;
    }

    const data: IBooksSingleOrderExcelData[] = [];

    for (const item of response.data) {
      data.push({
        bo_addr1: item.bo_addr1,
        bo_addr2: item.bo_addr2,
        bo_addrNum: item.bo_addrNum,
        bo_cancelDate: item.bo_cancelDate ? format(new Date(item.bo_cancelDate), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_cancelRequestDate: item.bo_cancelRequestDate ? format(new Date(item.bo_cancelRequestDate), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_cancelSubscribeDate: item.bo_cancelSubscribeDate ? format(new Date(item.bo_cancelSubscribeDate), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_createdAt: item.bo_createdAt ? format(new Date(item.bo_createdAt), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_deletedAt: item.bo_deletedAt ? format(new Date(item.bo_deletedAt), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_email: item.bo_email,
        bo_goodsName: item.bo_goodsName,
        bo_isAutoPay: item.bo_isAutoPay,
        bo_name: item.bo_name,
        bo_no: item.bo_no,
        bo_orderGoodsType: item.bo_orderGoodsType,
        bo_paymethod: item.bo_paymethod,
        bo_phone: item.bo_phone,
        bo_state: BooksOrderStateList[item.bo_state],
        bo_updatedAt: item.bo_updatedAt ? format(new Date(item.bo_updatedAt), 'yyyy-MM-dd kk:mm:ss') : '-',
        bo_userId: item.bo_userId,
        boc_applyNo: item.boc_applyNo,
        boc_invoiceCompany: item.boc_invoiceCompany,
        boc_invoiceNum: item.boc_invoiceNum,
        boc_no: item.boc_no,
        boc_price: item.boc_price,
        u_id: item.u_id,
        s_name: item.s_name,
        bo_studentId: item.bo_studentId,
      });
    }

    await ExcelDown('단품 주문 리스트', [
      {header: "주문 코드", key: "bo_no"},
      {header: "이름", key: "bo_name"},
      {header: "자녀명", key: "s_name"},
      {header: "ID(Email)", key: "bo_email"},
      {header: "전화번호", key: "bo_phone"},
      {header: "우편번호", key: "bo_addrNum"},
      {header: "주소1", key: "bo_addr1"},
      {header: "주소2", key: "bo_addr2"},
      {header: "주문 제품", key: "bo_goodsName"},
      {header: "승인번호", key: "boc_applyNo"},
      {header: "결제 금액", key: "boc_price"},
      {header: "결제일", key: "bo_createdAt"},
      {header: "취소 완료일", key: "bo_cancelDate"},
      {header: "상태", key: "bo_state"},
      {header: "배송사", key: "boc_invoiceCompany"},
      {header: "운송장 번호", key: "boc_invoiceNum"},
      {header: "앱 이용기간", key: ""},
    ], data);
  };

  useEffect(() => {
    const sessionParams = getSession<IBooksSingleOrderParams>('booksSingleOrderParams');
    if (sessionParams) {
      if (sessionParams.page) {
        setCurrent(sessionParams.page);
      }
      dispatch(booksSingleOrderDataList(sessionParams));
    } else {
      const data: IBooksSingleOrderParams = {
        ...params,
        page: current,
      };
      dispatch(booksSingleOrderDataList(data));
    }
  }, [dispatch, current]);


  useEffect(() => {
    if (params) {
      setSession('booksSingleOrderParams', params);
    }
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>결제관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='[단품] 단품 주문관리'/>
      <BooksSingleOrderFilterBox onSubmit={onSubmit}/>
      <div style={{height: 35}}>
        <span>total: {total}</span>
        <Button type='primary'
                icon={<FileExcelOutlined/>}
                style={{float: 'right'}}
                onClick={onExcelClick}>
          엑셀다운로드
        </Button>
      </div>
      <Row gutter={10}>
        <Col span={24}>
          <BooksSingleOrderListTable
            dataList={dataList ? dataList : []}
            orderLogModalShow={orderLogModalShow}
            onChange={onPageDataChange}
            orderAddressChangeModalShow={orderAddressChangeModalShow}/>
        </Col>
      </Row>
      <Pagination current={page} total={total} defaultPageSize={20} onChange={onPageChange}
                  style={{marginTop: '1.5rem'}} showSizeChanger={false}/>
      <BooksOrderLogModal
        visible={orderLogModalVisible}
        orderLogInfoData={logDataList}
        onCancel={orderLogModalClose}/>
      <BooksOrderAddressChangeModal
        visible={orderAddressChangeModalVisible}
        onChange={onPageDataChange}
        bocNo={selectBooksOrderComponentsNo}
        boNo={selectBooksOrderNo}
        userId={selectUserId}
        onCancel={orderAddressChangeModalClose}/>
    </>
  );
};

export default BooksSingleOrderPage;
