import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ILevelTestApplicant } from '../../models/levelTestApplicant.model';
import { format } from 'date-fns';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const DashboardLevelTestApplicantTable: FC = () => {
    const { levelTestApplicants } = useSelector((root: RootState) => root.levelTestApplicantState);

    const columns: ColumnsType<ILevelTestApplicant> = [
      {
        title: 'Id',
        render: (value, record) => record.id
      },
      {
        title: '신청일자',
        key: 'startDateTime',
        render: (value, record) => format(new Date(record.reservedDateTime), 'yyyy.MM.dd HH:mm')
      },
      {
        title: '회원',
        key: 'studentId',
        render: (value, record) =>
          <a
            href={`/customer/${record.student.user.id}`}
            target='_blank'
            style={LinkStyle}
          >
            {record.student.user.name}({record.student.user.id})
          </a>
      },
      {
        title: '학생',
        key: 'studentId',
        render: (value, record) => <>{record.student.name}({record.student.id})</>
      },
      {
        title: 'Tutor',
        key: 'staffId',
        render: (value, record) =>
          <a
            href={`/tutor/${record.staff.id}`}
            target='_blank'
            style={LinkStyle}
          >
            {record.staff.firstName} {record.staff.lastName}({record.staff.id})
          </a>
      },
      {
        title: '테스트(예정)시간',
        key: 'startDateTime',
        render: (value, record) =>
          <Link
            style={LinkStyle}
            to={`/level-test/${record.id}`}
          >
            {format(new Date(record.startDateTime), 'yyyy.MM.dd HH:mm')}
          </Link>
      },
      {
        title: '평가',
        render: (value, record) => record.isEvaluated ? 'Y' : 'N',
      },
      {
        title: '교육상담',
        render: (value, record) => record.levelTest.consultingDate ? 'Y' : 'N',
      },
      {
        title: 'Fail상담',
        render: (value, record) => record.levelTest.isFailed ? 'Y' : 'N',
      }
    ];

    return (
      <Table
        style={tableStyle}
        dataSource={levelTestApplicants}
        columns={columns}
        size='small'
        rowKey={record => record.id}
        pagination={false}
      />
    );
  }
;

export default DashboardLevelTestApplicantTable;
