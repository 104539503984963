import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IPaymentSchedule } from '../../models/payment.model';
import { getPaymentScheduleDate, isFirstLesson } from '../../libs/helper';
import { ScheduleType } from '../../models/Enum';
import { tableStyle } from '../../style/antdCssProperty';
import { setCreditTotal } from '../../store/payment.slice';
import { Link } from 'react-router-dom';

interface IProps {
  onDelete(paymentScheduleId: number): void;
}

const NoShowPaymentTable: FC<IProps> = ({ onDelete }) => {
    const dispatch = useDispatch();

    const { paymentSchedule, tutorPayment } = useSelector((root: RootState) => root.paymentState);
    const { user } = useSelector((root: RootState) => root.authState);

    const [filterSchedule, setFilterSchedule] = useState<IPaymentSchedule[]>([]);

    useEffect(() => {
      const newFilterSchedule = paymentSchedule.filter((item) => item.scheduleType === ScheduleType.TUTOR_NOSHOW || item.scheduleType === ScheduleType.ALL_NOSHOW);
      setFilterSchedule(newFilterSchedule);
      dispatch(setCreditTotal({ noShowCredit: newFilterSchedule.length * 10 }));
    }, [paymentSchedule]);

    const columns: ColumnsType<IPaymentSchedule> = [
      {
        title: '수업시간',
        key: 'startDateTime',
        render: (value, record) => getPaymentScheduleDate(record.startDateTime),
      },
      {
        title: '학생',
        key: 'student',
        render: (value, record) => `${record.student.name}(${record.student.id})`,
      },
      {
        title: '코스/차시',
        key: 'episode',
        render: (value, record) => `${record.episode.name} - Lesson ${isFirstLesson(record.episode.pageNo)}`,
      },
      {
        title: '단가',
        key: 'pay',
        render: () => '$10.00',
      },
      {
        title: '',
        render: (value, record) => <Button type='primary' onClick={() => onDelete(record.id)}
                                           disabled={tutorPayment?.paymentMaster.isPublished || user === null || user.id !== 1677}>삭제</Button>,
      },
      {
        title: '',
        render: (value, record) => <Link to={`/dashboard/${record.scheduleId}`}><Button type='primary'>이동</Button></Link>,
      },
    ];

    const summary = (pageData: IPaymentSchedule[]) => {
      return <Table.Summary.Row>
        <Table.Summary.Cell align='center' index={0} colSpan={3}>총 {pageData.length}건</Table.Summary.Cell>
        <Table.Summary.Cell align='center' index={1} colSpan={2}>${(pageData.length * 10).toFixed(2)}</Table.Summary.Cell>
      </Table.Summary.Row>;
    };

    return (
      <Table
        style={{ ...tableStyle, marginTop: '1.5rem' }}
        dataSource={filterSchedule}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
        title={() => 'Deduction (No-Shows Penalty)'}
        summary={summary}
      />
    );
  }
;

export default NoShowPaymentTable;
