export const BOOKS_ORDER_STATE_PAYMENT = 1; // 결제 완료
export const BOOKS_ORDER_STATE_RELEASE = 2; // 출고
export const BOOKS_ORDER_STATE_SUBSCRIPT_PAYMENT_ERROR = 3; // 자동결제실패
export const BOOKS_ORDER_STATE_CANCEL_REQUEST = 4; // 취소접수
export const BOOKS_ORDER_STATE_CANCEL_END = 5; // 취소완료
export const BOOKS_ORDER_STATE_CANCEL_REJECT = 6; // 취소 기각
export const BOOKS_ORDER_STATE_CANCEL_POSSIBLE = 7; // 취소 가능
export const BOOKS_ORDER_STATE_SUBSCRIPT_CANCEL = 8; // 구독 취소

/**
 * 교재 주문 상태 전체 리스트
 */
export const BooksOrderStateList = [
  '-',
  '결제 완료',
  '출고',
  '자동결제실패',
  '취소접수',
  '취소완료',
  '취소기각',
  '취소가능',
  '구독취소',
];

/**
 * 구독 주문 구독 취소 처리 가능 상태 리스트
 */
export const BooksSubscribeOrderSubscribeCancelPossibleStateList = [1, 2, 3, 6];

/**
 * 구독 주문 월 결제 취소 처리 가능 상태 리스트
 */
export const BooksSubscribeOrderMonthCancelPossibleStateList = [1, 2, 4, 7];

/**
 * 교재 주문 상세 정보 Interface
 */
export interface IGetBooksOrderDetailInfoData {
  no: number;
  subscribeMonth: number;
  state: number;
  applyNo: string;
  price: number;
  invoiceNum: string;
  addrNum: string;
  addr1: string;
  addr2: string;
  invoiceDate: string;
  invoiceCompany: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  booksOrderNo: number;
}

/**
 * 교재 주문 상세 정보 API Response
 */
export interface IGetBooksOrderDetailInfoDataResponse {
  dataList: IGetBooksOrderDetailInfoData[];
  orderUserId: number;
}

/**
 * 해당 회원 구독 주문 구독 취소 처리 API Response
 */
export interface IOrderCancelSubscribe {
  result: boolean;
  resultMessage: string;
}

/**
 * 해당 회원 구독 주문 월 결제 취소 처리 API Request Params
 */
export interface ISubscribeOrderMonthCancelParams {
  userId: number;
  bocNo: number;
  boNo: number;
}

/**
 * 해당 회원 구독 주문 월 결제 취소 처리 API Response
 */
export interface ISubscribeOrderMonthCancelResponse {
  result: boolean;
  resultMessage: string;
}

/**
 * 주문자 배송 정보 변경 처리 Params
 */
export interface ISetOrderAddressChangeParams {
  userId: number;
  boNo?: number;
  bocNo?: number;
  addrNo: string;
  addr1: string;
  addr2: string;
  implementer: string;
  implementerSite: 'lms';
}
