import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ColumnsType } from 'antd/lib/table';
import { ITutorExtraPayment } from '../../models/payment.model';
import { ExtraType } from '../../models/Enum';
import { Button, Table } from 'antd';
import { tableStyle } from '../../style/antdCssProperty';

interface IProps {
  onDelete(tutorExtraPaymentId: number): void;
}

const TutorExtraPaymentTable: FC<IProps> = ({ onDelete }) => {
  const { tutorExtraPaymentList, tutorPayment } = useSelector((root: RootState) => root.paymentState);
  const { user } = useSelector((root: RootState) => root.authState);

  const columns: ColumnsType<ITutorExtraPayment> = [
    {
      title: '항목명',
      dataIndex: 'name',
    },
    {
      title: '구분',
      render: (value, record) => record.extraType === ExtraType.BONUS ? 'Bonus' : 'Deduction',
    },
    {
      title: '단가',
      render: (value, record) => `$${record.amount.toFixed(2)}`,
    },
    {
      title: '갯수',
      dataIndex: 'quantinty',
    },
    {
      title: '금액',
      render: (value, record) => `$${(record.amount * record.quantinty).toFixed(2)}`,
    },
    {
      title: '삭제',
      render: (value, record) => <Button type='primary' onClick={() => onDelete(record.id)} disabled={tutorPayment?.paymentMaster.isPublished || user === null || user.id !== 1677}>삭제</Button>,
    },
  ];

  const summary = (pageData: ITutorExtraPayment[]) => {
    let sum = 0;
    for (const item of pageData) {
      if (item.extraType === ExtraType.BONUS) {
        sum += item.amount * item.quantinty;
      } else {
        sum -= item.amount * item.quantinty;
      }
    }
    return <Table.Summary.Row>
      <Table.Summary.Cell align='center' index={0} colSpan={4}>총 {pageData.length}건</Table.Summary.Cell>
      <Table.Summary.Cell align='center' index={1} colSpan={2}>${sum.toFixed(2)}</Table.Summary.Cell>
    </Table.Summary.Row>;
  };

  return (
    <Table
      style={{ ...tableStyle, marginTop: '1.5rem' }}
      dataSource={tutorExtraPaymentList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
      title={() => '추가 항목'}
      summary={summary}
    />
  );
};

export default TutorExtraPaymentTable;
