import { FC, useEffect, useState } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  doGetPayment,
  doGetTutorPaymentList, doPatchPaymentEmail,
  doPatchTutorPaymentTotalAll,
  initCreditTotal,
} from '../store/payment.slice';
import { Breadcrumb, PageHeader, Modal, message } from 'antd';
import { RootState } from '../store/rootReducer';
import TutorPaymentBox from '../components/payment/TutorPaymentBox';
import TutorPaymentTable from '../components/payment/TutorPaymentTable';

interface IMatch {
  id: string;
}

const TutorPaymentPage: FC = () => {
  const dispatch = useDispatch();
  const route = useRouteMatch<IMatch>();
  const paymentMasterId = Number(route.params.id);

  const { paymentMaster } = useSelector((root: RootState) => root.paymentState);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(doGetPayment(paymentMasterId));
    dispatch(doGetTutorPaymentList(paymentMasterId));
    dispatch(initCreditTotal());
  }, [dispatch, paymentMasterId]);

  const onShowModal = () => {
    setVisible(true);
  };

  const onModalOk = () => {
    dispatch(doPatchPaymentEmail(paymentMasterId));
    setVisible(false);
    message.success('메일발송에 성공했습니다.');
  };

  const onModalCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='/payment'>강사 급여 관리</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{paymentMaster?.title ?? ''}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={paymentMaster?.title ?? ''} />
      <TutorPaymentBox paymentMasterId={paymentMasterId} onEmailSubmitClick={onShowModal} />
      <TutorPaymentTable paymentMasterId={paymentMasterId} />
      <div style={{
        marginTop: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      </div>
      <Modal title='이메일 발송' visible={visible} onOk={onModalOk} onCancel={onModalCancel}>
        <p>이메일 발송을 하시면 자동적으로 급여 명세서가 마감됩니다.</p>
        <p>마감하시면 명세서를 변경하실수 없습니다.</p>
      </Modal>
    </>
  );
};

export default TutorPaymentPage;
