import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Input, AutoComplete, Select, Button, Upload, List, Modal, message } from 'antd';
import { DeleteOutlined, SaveFilled, UploadOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, AtomicBlockUtils, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'style/editor.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import { BoardRequest } from '../../models/Board';
import {
  boardCreate,
  boardRead,
  boardUpdate,
  doBoardCategoryList,
  doBoardFileDelete,
  doBoardFileList,
} from '../../store/board.slice';
import { RootState } from '../../store/rootReducer';
import Loader from '../Loader';
import { messageAdd, showMessage } from '../../store/message.slice';

interface IProps {
  editor: 'create' | 'update';
  bmId: number;
  id?: number;
}

interface IMatch {
  id?: string;
  type: string;
}

interface ILocation {
  id: string;
}

const { Option } = Select;

const BoardEditor: FC<IProps> = ({ editor, bmId }) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState('');
  const [boardCategoryId, setCategoryId] = useState(0);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attachmentId, setAttachmentId] = useState(0);
  const [attachmentListId, setAttachmentListId] = useState<Array<{ id: number; }>>([]);
  const [thumbnail, setThumbnail] = useState<File>();
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const { boardCategories } = useSelector((root: RootState) => root.boardState);
  const history = useHistory();
  const location = useLocation<ILocation>();

  const match = useRouteMatch<IMatch>();

  const { type } = match.params;

  const dispatch = useDispatch();
  const { board, isLoading, boardFiles } = useSelector((state: RootState) => state.boardState);

  const uploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_AXIOS_HOST}/api/boards/files`,
    onChange(info: any) {
      if (info.file.status === 'error') {
        dispatch(messageAdd(showMessage('error', info.file.response.message)));
      } else if (info.file.status === 'done') {
        const mimetype = info.file.response.mimetype.split('/');
        const newAttachmentListId = attachmentListId.slice();
        newAttachmentListId.push({ id: info.file.response.id });
        setAttachmentListId(newAttachmentListId);
        if (mimetype[0] !== 'image') {
          return;
        }
        const entityData = { src: info.file.response.url };
        const contentStateWithEntity = editorState.getCurrentContent().createEntity('IMAGE', 'IMMUTABLE', entityData);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        let newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
        setEditorState(newEditorState);
        dispatch(messageAdd(showMessage('success', 'success upload!!')));
      }
    },
    withCredentials: true,
  };

  const onAutoCompleteChange = (value: string) => {
    setTitle(value);
  };


  const onClick = async () => {
    const boardRequest: BoardRequest = {
      title: title,
      boardMasterId: bmId,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      boardCategoryId: boardCategoryId !== 0 ? boardCategoryId : bmId <= 3 ? bmId : bmId === 5 ? 9 : 16,
    };
    if (attachmentListId.length >= 1) {
      boardRequest.attachment = attachmentListId;
    }
    if (type === 'create') {
      if (title) {
        if (thumbnail) {
          const formData = new FormData();
          formData.append('file', thumbnail);
          await dispatch(boardCreate(boardRequest, formData));
        } else {
          message.warn('썸네일을 등록해주세요.');
          return;
        }
        if (bmId >= 3) {
          history.push(`/resource/${bmId}`);
        } else {
          history.push('/boards');
        }
      }
    } else {
      if (location.state) {
        if (board?.thumbnail) {
          if (thumbnail && board.thumbnail !== thumbnailUrl) {
            const formData = new FormData();
            formData.append('file', thumbnail);
            await dispatch(boardUpdate(Number(location.state), boardRequest, formData));
          }
        }
        await dispatch(boardUpdate(Number(location.state), boardRequest));
      }
      if (bmId >= 3) {
        history.push(`/resource/${bmId}`);
      } else {
        history.push('/boards');
      }
    }
  };

  const onBoardCategorySelect = (value: number) => {
    setCategoryId(value);
  };

  useEffect(() => {
    dispatch(doBoardCategoryList(bmId));
    if (location.state) {
      dispatch(boardRead(Number(location.state)));
      dispatch(doBoardFileList(Number(location.state)));
    }
  }, [dispatch, location.state, bmId]);

  useEffect(() => {
    if (board && location.state) {
      setTitle(board.title);
      if (!board.content) {
        setEditorState(EditorState.createEmpty());
        return;
      }
      if (board.thumbnail) {
        const stringArray = board.thumbnail.split('/');
        setThumbnailUrl(stringArray[stringArray.length - 1]);
      }
      const blocksFromHtml = htmlToDraft(board.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
      if (board.boardCategory) {
        setCategoryId(board.boardCategory.id);
      }
    }
  }, [board, location.state]);

  useEffect(() => {
    if (boardCategories.length !== 0) {
      setCategoryId(boardCategories[0].id);
    }
  }, [boardCategories]);

  if (isLoading && editor === 'update') {
    return <Loader />;
  }

  const onDeleteClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, attachmentId: number) => {
    event.preventDefault();
    setIsModalVisible(true);
    setAttachmentId(attachmentId);
  };

  const handleOk = () => {
    dispatch(doBoardFileDelete(attachmentId));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onThumbnailClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileElem = fileRef.current;
    if (fileElem) {
      fileElem.click();
    }
  };

  const onThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (file) {
      setThumbnail(file);
      setThumbnailUrl(file.name);
    }
  };

  return (
    <>
      <Input.Group size='large' style={{ minWidth: '100%' }}>
        <AutoComplete
          style={{ width: (boardCategories.length >= 1) ? '85%' : '100%' }}
          placeholder='title here'
          onChange={onAutoCompleteChange}
          value={title} />
        {
          boardCategories.length >= 1 && <Select
            style={{ width: '15%' }}
            value={boardCategoryId}
            onChange={onBoardCategorySelect}
          >
            {boardCategories.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        }
      </Input.Group>
      <div style={{
        marginBottom: '1.5rem',
      }} />
      <Editor
        editorState={editorState}
        toolbarClassName='rdw-editor-toolbar'
        wrapperClassName='rdw-editor-wrapper'
        editorClassName='rdw-editor-main'
        onEditorStateChange={setEditorState} />
      <div style={{ marginBottom: '1.5rem', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p style={{ flexShrink: 0, marginRight: '0.5rem' }}>
          썸네일:
        </p>
        <Input type='text' onClick={onThumbnailClick} style={{ flexGrow: 0, flexShrink: 0 }}
               value={thumbnailUrl ?? ''} />
        <input type='file' onChange={onThumbnailChange} style={{ display: 'none' }} ref={fileRef} />
      </div>
      <Upload {...uploadProps} >
        <Button icon={<UploadOutlined />}>
          Click to Upload
        </Button>
      </Upload>
      {
        (board && location.state && boardFiles.length !== 0) && (
          <List dataSource={boardFiles} renderItem={(item) => (
            <>
              <List.Item
                actions={[<Button icon={<DeleteOutlined />} onClick={e => onDeleteClick(e, item.id)} />]}
              >
                <a href={item.url} target='_blank'>{item.filename}</a>
              </List.Item>
            </>
          )} />
        )
      }

      <Button
        type='default'
        block
        size='large'
        icon={<SaveFilled />}
        onClick={onClick}>
        Save
      </Button>
      <Modal title='파일 삭제' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>정말로 삭제하실건가요?</p>
      </Modal>
    </>
  );
};

export default BoardEditor;
