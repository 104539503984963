import {AppThunk} from "./index";
import {
  BrotherApplyList,
  BrotherApplyParams,
  IGetBrotherApplyListSuccess
} from "../models/BrotherApply";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {list} from "../api/customerBrotherApply";

interface IBrotherApplyState {
  applyList: BrotherApplyList[] | null;
  total: number;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBrotherApplyState = {
  applyList: [],
  total: 0,
  isLoading: false,
  error: null,
};

const brotherApplySlicer = createSlice({
  name: 'customerBrotherApply',
  initialState,
  reducers: {
    requestStart: (state: IBrotherApplyState) => {
      state.error = null;
      state.isLoading = true;
    },
    listSuccess: (state: IBrotherApplyState, action: PayloadAction<IGetBrotherApplyListSuccess>) => {
      state.total = action.payload.total;
      state.applyList = action.payload.data;
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IBrotherApplyState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  listSuccess,
  requestFailure,
} = brotherApplySlicer.actions;

export default brotherApplySlicer.reducer;

export const brotherApplyList = (param?: BrotherApplyParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(param);
    const data: IGetBrotherApplyListSuccess = {
      data: response.data, total: response.headers['total'],
    };
    dispatch(listSuccess(data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};
