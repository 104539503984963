import React, {FC, useState} from "react";
import {
  BooksSingleOrderSearchStateList,
  IBooksSingleOrderDataList
} from "../models/BooksSingleOrder";
import {ColumnsType} from "antd/lib/table";
import {Button, Popconfirm, Table} from "antd";
import {format} from "date-fns";
import {LinkStyle, tableStyle} from "../style/antdCssProperty";
import {Link} from "react-router-dom";
import {FileSyncOutlined, SearchOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {cancelSingleOrder} from "../store/booksSingleOrder.slice";
import {BOOKS_ORDER_STATE_CANCEL_END} from "../models/BooksOrder";
import {RootState} from "../store/rootReducer";

export interface IProps {
  dataList: IBooksSingleOrderDataList[];

  orderLogModalShow(boNo: number, view: boolean, boc_no?: number): void;

  onChange(): void;

  orderAddressChangeModalShow(boNo: number, bocNo: number, userId: number, view: boolean): void;
}


const BooksSingleOrderListTable: FC<IProps> = ({
                                                 dataList,
                                                 orderLogModalShow,
                                                 onChange,
                                                 orderAddressChangeModalShow
                                               }) => {
  const dispatch = useDispatch();
  const {user} = useSelector((state: RootState) => state.authState);
  const [cancelOrderBtnLoading, setCancelOrderBtnLoading] = useState(false);

  const orderCancelBtn = async (boNo: number, bocNo: number, userName: string) => {
    setCancelOrderBtnLoading(true);
    await dispatch(cancelSingleOrder({
      booksOrderComponentsNo: bocNo,
      booksOrderNo: boNo,
      implementer: userName,
      implementerSite: "lms",
    }));
    setCancelOrderBtnLoading(false);
    onChange();
  };

  const columns: ColumnsType<IBooksSingleOrderDataList> = [
    {
      title: '주문 코드',
      dataIndex: 'bo_no',
      fixed: 'left',
      width: 100,
    },
    {
      title: '이름',
      fixed: 'left',
      width: 150,
      render: (record: IBooksSingleOrderDataList) => {
        return <Link to={`customer/${record.u_id}`} style={LinkStyle}>{record.bo_name}<br/>({record.u_id})</Link>;
      },
    },
    {
      title: '자녀명',
      dataIndex: 's_name',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'ID(Email)',
      dataIndex: 'bo_email',
      fixed: 'left',
      width: 200,
    },
    {
      title: '전화번호',
      dataIndex: 'bo_phone',
      fixed: 'left',
      width: 150,
    },
    {
      title: '주소',
      width: 300,
      render: (record: IBooksSingleOrderDataList) => {
        return <>
          {record.bo_addrNum}<br/>
          {record.bo_addr1} {record.bo_addr2}
        </>
      },
    },
    {
      title: '주문 제품',
      width: 130,
      dataIndex: 'bo_goodsName',
    },
    {
      title: '승인번호',
      width: 130,
      dataIndex: 'boc_applyNo',
    },
    {
      title: '결제 금액',
      width: 130,
      render: (record: IBooksSingleOrderDataList) => {
        return record.boc_price.toLocaleString('ko-kr');
      },
    },
    {
      title: '결제일',
      width: 200,
      render: (record: IBooksSingleOrderDataList) => {
        return format(new Date(record.bo_createdAt), 'yyyy-MM-dd kk:mm:ss');
      },
    },
    {
      title: '취소 완료일',
      width: 200,
      render: (record: IBooksSingleOrderDataList) => {
        return record.bo_cancelDate ? format(new Date(record.bo_cancelDate), 'yyyy-MM-dd kk:mm:ss') : '';
      }
    },
    {
      title: '상태',
      width: 130,
      key: 6,
      render: (record: IBooksSingleOrderDataList) => {
        return Object.entries(BooksSingleOrderSearchStateList).map((item) => {
          return String(record.bo_state) === item[0] ? item[1] : '';
        })
      },
    },
    {
      title: '배송사',
      width: 130,
      dataIndex: 'boc_invoiceCompany',
    },
    {
      title: '운송장 번호',
      width: 130,
      dataIndex: 'boc_invoiceNum',
    },
    {
      title: '앱 이용기간/내역',
      width: 150,
    },
    {
      title: '결제 확인',
      fixed: 'right',
      width: 130,
      render: (record: IBooksSingleOrderDataList) => {
        return <>
          <Popconfirm key="cancelOrderConfirm"
                      title="해당 주문을 취소하시겠습니까?"
                      onConfirm={() => {
                        orderCancelBtn(record.bo_no, record.boc_no, `${user ? user.lastName + user.firstName : ''}`).then()
                      }}
                      okText="결제취소"
                      cancelText="닫기"
                      disabled={record.bo_state === BOOKS_ORDER_STATE_CANCEL_END}>
            <Button type='primary' danger
                    loading={cancelOrderBtnLoading}
                    disabled={record.bo_state === BOOKS_ORDER_STATE_CANCEL_END}>
              결제 취소
            </Button>
          </Popconfirm>
        </>;
      },
    },
    {
      title: '관리',
      fixed: 'right',
      width: 130,
      render: (record: IBooksSingleOrderDataList) => {
        return <>
          <Button type='primary' icon={<SearchOutlined/>} onClick={() => orderLogModalShow(record.bo_no, true)}>
            변경 기록
          </Button>
          <Button
            style={{marginTop: '5px'}}
            type='primary'
            onClick={() => orderAddressChangeModalShow(record.bo_no, record.boc_no, record.bo_userId, true)}
            icon={<FileSyncOutlined/>}>
            주소 변경
          </Button>
        </>;
      },
    },
  ];

  return (
    <>
      <Table dataSource={dataList}
             columns={columns}
             size='small'
             pagination={false}
             rowKey={(record) => `${record.bo_no}`}
             style={tableStyle}
             scroll={{x: 2000}}
      />
    </>
  );
};

export default BooksSingleOrderListTable;
