import React, { FC, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, message, PageHeader } from 'antd';
import DashboardInfo from '../components/dashboard/DashboardInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  doGetDashboardSchedule,
  doGetDashboardScheduleList,
  doPatchDashboardSchedule,
  doPatchScheduleFail,
} from '../store/schedule.slice';
import { RootState } from '../store/rootReducer';
import DashboardVideoList from '../components/dashboard/DashboardVideoList';
import { doGetStudyroom, doGetStudyroomLogList } from '../store/studyroom.slice';
import { doGetEvaluation, doGetEvaluation2, doGetEvaluationDescriptionList } from '../store/evaluation.slice';
import DashboardEvaluation from '../components/dashboard/DashboardEvaluation';
import { doGetFeedback } from '../store/feedback.slice';
import DashboardFeedback from '../components/dashboard/DashboardFeedback';
import DashboardModal from '../components/dashboard/DashboardModal';
import { IDashboardViewChangeUpdate, IScheduleFail } from '../models/Schedule';
import { FailReason, ScheduleStatus } from '../models/Enum';
import DashboardFailReason from '../components/dashboard/DashboardFailReason';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DashboardEvaluation2 from '../components/dashboard/DashboardEvaluation2';

interface IMatch {
  id: string;
}

const DashboardViewPage: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const scheduleId = Number(match.params.id);

  const [visible, setVisible] = useState(false);
  const [scheduleFailVisible, setScheduleFailVisible] = useState(false);
  const [failReason, setFailReason] = useState<FailReason>();
  const [updateAt, setUpdateAt] = useState(new Date());

  const { dashboardRead, dashboardReadList, error } = useSelector((root: RootState) => root.scheduleState);
  const { studyroomVideoList } = useSelector((root: RootState) => root.studyroomState);
  const { evaluation, evaluation2 } = useSelector((root: RootState) => root.evaluationState);
  const { feedback } = useSelector((root: RootState) => root.feedbackState);

  useEffect(() => {
    dispatch(doGetDashboardSchedule(scheduleId));
    dispatch(doGetStudyroom(scheduleId));
    dispatch(doGetEvaluation(scheduleId));
    dispatch(doGetFeedback(scheduleId));
    dispatch(doGetStudyroomLogList(scheduleId));
  }, [dispatch, scheduleId]);

  useEffect(() => {
    if (error && error.response) {
      switch (error.response.status) {
        case 409:
          message.warn('스케줄 정보가 업데이트 되었습니다. 새로 고침 해주세요.');
          return;
      }
    }
  }, [error]);

  useEffect(() => {
    if (evaluation?.version === 1) {
      dispatch(doGetEvaluation2(scheduleId));
    } else {
      dispatch(doGetEvaluationDescriptionList());
    }
  }, [evaluation]);

  const showModal = () => {
    if (dashboardRead) {
      if (dashboardRead.status === ScheduleStatus.RESERVED) {
        dispatch(doGetDashboardScheduleList({ startDateTime: dashboardRead.startDateTime }));
      } else {
        message.info('예약된 강사만 변경 가능합니다.');
        return;
      }
    }
    setVisible(true);
  };

  const handleOk = (scheduleId: number) => {
    if (scheduleId === 0) {
      message.info('튜터를 선택해주세요.');
      return;
    }
    const filter = dashboardReadList.filter(item => item.id === scheduleId);
    if (filter.length !== 0 && dashboardRead) {
      const data: IDashboardViewChangeUpdate = {
        changeStaffId: filter[0].staff.id,
        deletedId: filter[0].id,
        id: dashboardRead.id,
        originalStaffId: dashboardRead.staff.id,
        updateAt: format(utcToZonedTime(updateAt, 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
      };
      dispatch(doPatchDashboardSchedule(data));
    } else {
      return;
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showScheduleFail = (failReason: FailReason) => {
    setFailReason(failReason);
    setScheduleFailVisible(true);
  };

  const handleFailReasonOk = (failReasonDetail: string, isClassRecovery: boolean, isEpisodeRecovery: boolean) => {
    if (!failReason) {
      return;
    }
    const body: IScheduleFail = {
      failReason,
      failReasonDetail: failReasonDetail,
    };
    if (isClassRecovery) {
      body.isClassRecovery = true;
    }
    if (isEpisodeRecovery) {
      body.isEpisodeRecovery = true;
    }
    dispatch(doPatchScheduleFail(scheduleId, body));
    setScheduleFailVisible(false);
  };

  const handleFailReasonCancel = () => {
    setScheduleFailVisible(false);
  };

  useEffect(() => {
    console.log('evaluation: ', evaluation);
  }, [evaluation]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>스케줄 정보</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='스케줄 정보' />
      {dashboardRead &&
      <DashboardInfo dashboard={dashboardRead} showModal={showModal} showFailModal={showScheduleFail} />}
      <DashboardVideoList studyroomVideoList={studyroomVideoList} />
      {evaluation ? evaluation.version === 1 ? <DashboardEvaluation evaluation={evaluation2} /> :
        <DashboardEvaluation2 /> : null}
      {feedback && <DashboardFeedback feedback={feedback} />}
      <DashboardModal visible={visible} dashboardList={dashboardReadList} onOk={handleOk} onCancel={handleCancel} />
      <DashboardFailReason visible={scheduleFailVisible} onOk={handleFailReasonOk} onCancel={handleFailReasonCancel} />
    </>
  );
};

export default DashboardViewPage;
