import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ITutorPayment } from '../../models/payment.model';
import { AddComma } from '../../libs/helper';
import { tableStyle } from '../../style/antdCssProperty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

interface IProps {
  paymentMasterId?: number;
}

const TutorPaymentTable: FC<IProps> = ({ paymentMasterId }) => {
  const dispatch = useDispatch();

  const { tutorPaymentList, isCheckboxUpdate } = useSelector((root: RootState) => root.paymentState);

  const columns: ColumnsType<ITutorPayment> = [
    {
      title: '이메일',
      key: 'email',
      render: (value, record) => record.staff.email,
    },
    {
      title: 'Id',
      key: 'id',
      render: (value, record) => record.staff.id,
    },
    {
      title: '강사 계약일',
      key: 'tutorContract',
      render: (value, record) => `${record.tutorContract.startDate} ~ ${record.tutorContract.endDate}`,
    },
    {
      title: 'First Name',
      key: 'firstName',
      render: (value, record) => record.staff.firstName,
    },
    {
      title: 'Last Name',
      key: 'lastName',
      render: (value, record) => record.staff.lastName,
    },
    {
      title: 'Total',
      key: 'total',
      render: (value, record) => `$${AddComma(record.totalPay, true)}`,
    },
    {
      title: 'Base Pay',
      key: 'base',
      render: (value, record) => `$${record.classPerPay.toFixed(2)}`,
    },
    {
      title: '최근 수정일시',
      key: 'updatedAt',
      render: (value, record) => `${format(utcToZonedTime(new Date(record.updatedAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss')}`,
    },
    {
      title: '',
      render: (value, record) => <Link to={`/payment/${paymentMasterId}/tutor/${record.staff.id}`}><Button
        type='primary'>상세</Button></Link>,
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={tutorPaymentList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default TutorPaymentTable;
