import React, {FC, useState} from "react";
import {BooksSubscribeOrderSearchStateList, IBooksSubscribeOrderDataList} from "../models/BooksSubscribeOrder";
import {useDispatch} from "react-redux";
import {ColumnsType} from "antd/lib/table";
import {LinkStyle, tableStyle} from "../style/antdCssProperty";
import {Button, Popconfirm, Table} from "antd";
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {
  BooksSubscribeOrderSubscribeCancelPossibleStateList
} from "../models/BooksOrder";
import {FileSyncOutlined, SearchOutlined} from "@ant-design/icons";
import {setBooksOrderCancelSubscribe} from "../store/booksSubscribeOrder.slice";

export interface IProps {
  dataList: IBooksSubscribeOrderDataList[];

  orderLogModalShow(boNo: number, view: boolean, boc_no?: number): void;

  onChange(): void;

  orderDetailInfoModalShow(boNo: number, userId: number, view: boolean): void;

  orderAddressChangeModalShow(boNo: number, bocNo: number, userId: number, view: boolean): void;
}

const BooksSubscribeOrderListTable: FC<IProps> = ({
                                                    dataList,
                                                    orderLogModalShow,
                                                    onChange,
                                                    orderDetailInfoModalShow,
                                                    orderAddressChangeModalShow
                                                  }) => {
  const dispatch = useDispatch();
  const [subscribeCancelImpossibleBtnLoading, setSubscribeCancelImpossibleBtnLoading] = useState(false);

  const orderSubscribeCancel = async (userId: number) => {
    setSubscribeCancelImpossibleBtnLoading(true);
    await dispatch(setBooksOrderCancelSubscribe(userId));
    setSubscribeCancelImpossibleBtnLoading(false);
    onChange();
  };

  const columns: ColumnsType<IBooksSubscribeOrderDataList> = [
    {
      title: '주문 코드',
      dataIndex: 'bo_no',
      fixed: 'left',
      width: 100,
    },
    {
      title: '이름',
      fixed: 'left',
      width: 150,
      render: (record: IBooksSubscribeOrderDataList) => {
        return <Link to={`customer/${record.u_id}`} style={LinkStyle}>{record.bo_name}<br/>({record.u_id})</Link>;
      },
    },
    {
      title: 'ID(Email)',
      dataIndex: 'bo_email',
      fixed: 'left',
      width: 200,
    },
    {
      title: '구독 주문 상태',
      width: 130,
      fixed: 'left',
      render: (record: IBooksSubscribeOrderDataList) => {
        return Object.entries(BooksSubscribeOrderSearchStateList).map((item) => {
          return String(record.bo_state) === item[0] ? item[1] : '';
        })
      },
    },
    {
      title: '결제 회차',
      width: 150,
      render: (record: IBooksSubscribeOrderDataList) => {
        return <>
          {record.maxSubscribeMonth} / 10
        </>;
      },
    },
    {
      title: '자녀명',
      dataIndex: 's_name',
      width: 150,
    },
    {
      title: '전화번호',
      dataIndex: 'bo_phone',
      width: 150,
    },
    {
      title: '주소',
      width: 300,
      render: (record: IBooksSubscribeOrderDataList) => {
        return <>
          {record.bo_addrNum}<br/>
          {record.bo_addr1} {record.bo_addr2}
        </>
      },
    },
    {
      title: '주문 제품',
      width: 130,
      dataIndex: 'bo_goodsName',
    },
    {
      title: '구독 시작일',
      width: 200,
      render: (record: IBooksSubscribeOrderDataList) => {
        return format(new Date(record.bo_createdAt), 'yyyy-MM-dd kk:mm:ss');
      },
    },
    {
      title: '앱 이용기간 / 내역',
      width: 150,
    },
    {
      title: '주문 내역',
      fixed: 'right',
      width: 130,
      render: (record: IBooksSubscribeOrderDataList) => {
        return <>
          <Button type='primary' icon={<SearchOutlined/>} onClick={() => orderDetailInfoModalShow(record.bo_no, record.bo_userId, true)}>
            상세보기
          </Button>
        </>;
      },
    },
    {
      title: '관리',
      fixed: 'right',
      width: 130,
      render: (record: IBooksSubscribeOrderDataList) => {
        const subscribeCancelImpossible: boolean = (BooksSubscribeOrderSubscribeCancelPossibleStateList.indexOf(record.bo_state) === -1);
        return <>
          <Popconfirm
            key="subscribeCancelImpossibleConfirm"
            title="해당 주문을 구독 취소 처리하시겠습니까?(결제는 취소되지 않습니다.)"
            onConfirm={() => {orderSubscribeCancel(record.bo_userId).then()}}
            okText="구독 취소"
            cancelText="닫기"
            disabled={subscribeCancelImpossible}>
            <Button
              type='primary' danger
              loading={subscribeCancelImpossibleBtnLoading}
              disabled={subscribeCancelImpossible}>
              구독 취소
            </Button>
          </Popconfirm>
          <Button
            style={{marginTop: '5px'}}
            type='primary'
            onClick={() => orderAddressChangeModalShow(record.bo_no, 0, record.bo_userId, true)}
            icon={<FileSyncOutlined />}>
            주소 변경
          </Button>
          <Button
            type='primary' icon={<SearchOutlined/>} style={{marginTop: '5px'}}
            onClick={() => orderLogModalShow(record.bo_no, true)}>
            변경 기록
          </Button>
        </>;
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={dataList}
        columns={columns}
        size='small'
        pagination={false}
        rowKey={(record) => `${record.bo_no}`}
        style={tableStyle}
        scroll={{x: 2000}}
      />
    </>
  );
};

export default BooksSubscribeOrderListTable;
