import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DashboardMain from '../components/dashboard/DashboardMain';
import DashboardLevelTestApplicantTable from '../components/dashboard/DashboardLevelTestApplicantTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { ILevelTestApplicantParam } from '../models/levelTestApplicant.model';
import { doGetLevelTestApplicantList } from '../store/levelTestApplicant.slice';
import { Button, Breadcrumb, PageHeader, Pagination } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { excelInit, getMonthDay } from '../libs/helper';
import { addHours, format } from 'date-fns';
import { dashboardScheduleList as dashboardScheduleListAPI } from '../api/schedule';
import { utcToZonedTime } from 'date-fns-tz';
import CustomDatePicker from '../components/common/CustomDatePicker';
import { FailReason, ScheduleStatus } from '../models/Enum';
import { getSession, setSession } from '../libs/session';

const LevelTestBoardPage: FC = () => {
  const dispatch = useDispatch();
  const { levelTestApplicants: dashboardScheduleList, total } = useSelector((root: RootState) => root.levelTestApplicantState);

  const [params, setParams] = useState<ILevelTestApplicantParam>();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const sessionParams = getSession<ILevelTestApplicantParam>('levelTestApplicants');
    if (sessionParams) {
      if (sessionParams.pageNumber) {
        setPageNumber(sessionParams.pageNumber);
      }
      dispatch(doGetLevelTestApplicantList(sessionParams));
    } else {
      const data: ILevelTestApplicantParam = { ...params, pageNumber };
      setParams(data);
      dispatch(doGetLevelTestApplicantList(data));
    }
  }, [dispatch, pageNumber]);

  useEffect(() => {
    if (params) {
      setSession('levelTestApplicants', params);
    }
  }, [params]);

  const onPageChange = (pageNumber: number) => {
    const newParams: ILevelTestApplicantParam = { ...params, pageNumber };
    setSession('levelTestApplicants', newParams);
    setPageNumber(pageNumber);
  };

  const onSubmit = (params: ILevelTestApplicantParam) => {
    params.pageNumber = 1;
    setPageNumber(1);
    setParams(params);
    dispatch(doGetLevelTestApplicantList(params));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/level-test'>체험수업 현황</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>체험수업 신청자</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='체험수업 신청자' />
      <DashboardMain
        title='levelTestApplicants'
        table={<DashboardLevelTestApplicantTable />}
        sessionName='levelTestApplicants'
        onChangeDate={() => {}}
        onSubmit={onSubmit}
        total={total}
        isTrialClass
      />
      <Pagination
        current={pageNumber}
        total={total}
        defaultPageSize={20}
        onChange={onPageChange}
        style={{ marginTop: '1.5rem' }}
        showSizeChanger={false}
      />
    </>
  );
};

export default LevelTestBoardPage;
