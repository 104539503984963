import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import toDate from 'date-fns/toDate';
import { format, isAfter, isBefore, addMinutes, subHours, addDays } from 'date-fns';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { ICreditTotal, ITierBonus, ITierBonusResult } from '../models/payment.model';
import { excelColumn } from './data';
import { IUserTransactionParam } from '../models/UserTransaction';

export const localDateFormat = (date: string, timeCity?: string) => {
  if (timeCity) {
    return toDate(zonedTimeToUtc(date, timeCity)).toLocaleDateString();
  } else {
    return toDate(zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone)).toLocaleDateString();
  }
};

export const getDayOfWeekString = (dayOfWeek: number): string => {
  const dayOfWeekString = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  return dayOfWeekString[dayOfWeek];
};

export const dateFormat = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const setCalendarFormat = (dateTime: string): Date => {
  return new Date(`${dateTime.substr(0, 10)} ${dateTime.substr(11, 8)}`);
};

export const toCalTimeZone = (dateTime: string | Date, timeZone: string) => {
  const t = utcToZonedTime(dateTime, timeZone);
  return setCalendarFormat(format(t, 'yyyy-MM-dd HH:mm:00'));
};

export const thisWeek = () => {
  const curr = new Date();
  const firstDay = format(
    new Date(curr.setDate(curr.getDate() - curr.getDay())),
    'yyyy-MM-dd',
  );
  const lastDay = format(
    new Date(curr.setDate(curr.getDate() - curr.getDay() + 7)),
    'yyyy-MM-dd',
  );
  return {
    firstDay,
    lastDay,
  };
};

export const contractIsActive = (startDate: string, endDate: string) => {
  return isAfter(new Date(), new Date(startDate)) && isBefore(new Date(), new Date(endDate));
};

export const settingCalender = (date: string | Date, timezone: string) => {
  return utcToZonedTime(date, timezone);
};

export const getExcelColumnWidth = (column: string) => {
  switch (column) {
    case 'studentName':
    case 'firstName':
    case 'lastName':
    case 'applicantStatus':
    case 'applicantSteps':
    case 'phone':
      return 15;
    case 'createdAt':
    case 'studentEnterDateTime':
    case 'tutorEnterDateTime':
    case 'startDateTime':
    case 'isSubscription':
      return 20;
    case 'countryName':
    case 'episodeName':
    case 'episodeLessonName':
      return 25;
    case 'email':
    case 'userEmail':
      return 30;
    case 'courseName':
      return 40;
    default:
      return 10;
  }
};

export const getExcelAddColumnWidth = (column: string): number => {
  switch (column) {
    case 'id':
      return 9.8;
    case 'firstName':
      return 16.8;
    case 'lastName':
      return 15.2;
    case 'email':
      return 11;
    case 'totalClassesTaught':
      return 24.8;
    case 'studentNoShowStudy':
      return 28.4;
    case 'itIssuePayment':
      return 20.4;
    case 'tierBonus':
      return 14.4;
    case 'creditBonus':
      return 15.8;
    case 'substituteClassesBonus':
      return 30.2;
    case 'deductionCancellation':
      return 40.4;
    case 'deductionNoShow':
      return 37.4;
    default:
      return 10;
  }
};

export const excelInit = async (sheet: string, name: string, data: Object[], addData?: Object[]) => {
  const workbook = new Excel.Workbook();
  const workSheet = workbook.addWorksheet(sheet);
  const columns: Partial<Excel.Column>[] = [];
  for (const item in data[0]) {
    columns.push({
      header: item,
      key: item,
      width: getExcelColumnWidth(item),
    });
  }
  workSheet.columns = columns;
  workSheet.addRows(data);
  if (addData) {
    const newWorkSheet = workbook.addWorksheet('강사별 급여 관리');
    const columns: Partial<Excel.Column>[] = [];
    for (const item in addData[0]) {
      columns.push({
        header: item,
        key: item,
        width: getExcelAddColumnWidth(item),
      });
    }
    newWorkSheet.columns = columns;
    newWorkSheet.addRows(addData);
    newWorkSheet.columns = columns.map((item) => {
      switch (item.header) {
        case 'totalClassesTaught':
          return {
            header: 'Total Classes Taught',
            key: 'Total Classes Taught',
            width: item.width,
          };
        case 'studentNoShowStudy':
          return {
            header: 'Student NoShow Study',
            key: 'Student NoShow Study',
            width: item.width,
          };
        case 'itIssuePayment':
          return {
            header: 'IT Issue Payment',
            key: 'IT Issue Payment',
            width: item.width,
          };
        case 'tierBonus':
          return {
            header: 'Tier Bonus',
            key: 'Tier Bonus',
            width: item.width,
          };
        case 'creditBonus':
          return {
            header: 'Credit Bonus',
            key: 'Credit Bonus',
            width: item.width,
          };
        case 'substituteClassesBonus':
          return {
            header: 'Substitute Classes Bonus',
            key: 'Substitute Classes Bonus',
            width: item.width,
          };
        case 'deductionCancellation':
          return {
            header: 'Deduction (24-Hour cancellation)',
            key: 'Deduction (24-Hour cancellation)',
            width: item.width,
          };
        case 'deductionNoShow':
          return {
            header: 'Deduction (No-Shows Penalty)',
            key: 'Deduction (No-Shows Penalty)',
            width: item.width,
          };
        default:
          return item;
      }
    });
  }
  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${name}.xlsx`);
};

export function getMonthDay(month: string) {
  const dateArray = month.split('-');
  const firstDay = zonedTimeToUtc(new Date(`${month}-1`), 'Asia/Seoul');
  const lastDay = zonedTimeToUtc(new Date(Number(dateArray[0]), Number(dateArray[1]), 0), 'Asia/Seoul');
  return { firstDay, lastDay };
}

export function isStudying(date: string | Date): boolean {
  const today = new Date();
  const startDateTime = new Date(date);
  return isAfter(today, startDateTime) && isBefore(today, addMinutes(startDateTime, 30));
}

export const AddComma = (num: number, isCurrency = false) => {
  let numString: string;
  if (isCurrency) {
    numString = num.toFixed(2);
  } else {
    numString = String(num);
  }
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return numString.replace(regexp, ',');
};

export const getPaymentDate = (date: string) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const getSeoulDate = (date: string | Date) => {
  return utcToZonedTime(new Date(date), 'Asia/Seoul');
};

export const getPaymentScheduleDate = (date: string | Date) => {
  return format(getSeoulDate(date), 'yyyy.MM.dd HH:mm:ss');
};

export const isFirstLesson = (pageNo: number): number => {
  return (pageNo % 2) + 1;
};

export const getBonus = (bonus: ITierBonus, total: number) => {
  let current = 0;
  let currentTotal = total;
  const tierBonusResult: ITierBonusResult[] = [];
  for (const item of bonus.tierBonusDetails) {
    current = currentTotal - item.bonusMin + 1;
    tierBonusResult.push({
      total: current,
      totalCredit: +(current * item.credit).toFixed(2),
    });
    currentTotal -= current;
  }
  return tierBonusResult.reverse();
};

export const getCreditTotal = (creditTotal: ICreditTotal): number => {
  let sum = 0;
  for (const key in creditTotal) {
    if (key === 'cancellationCredit' || key === 'noShowCredit') {
      // @ts-ignore
      sum -= creditTotal[key];
    } else {
      // @ts-ignore
      sum += creditTotal[key];
    }
  }
  return sum;
};

export const getNewline = (str: string) => {
  return str.replace(/\n/g, '<br/>');
};

// 수업시간 취소 날짜
export const getCancelType = (startDateTime: string, createdAt: string): string => {
  const start = new Date(startDateTime);
  const cancel = new Date(createdAt);
  if (isAfter(subHours(start, 24), cancel)) {
    return 'CANCEL1';
  }
  if (isBefore(subHours(start, 24), cancel) && isAfter(subHours(start, 2), cancel)) {
    return 'CANCEL2';
  }
  if (isBefore(subHours(start, 2), cancel)) {
    return 'CANCEL3';
  }
  return '';
};

export const getTransactionDate = ({
                                     startDate,
                                     endDate,
                                   }: IUserTransactionParam): [string | undefined, string | undefined] => {
  let newEndDate: string | undefined;
  let newStartDate: string | undefined;
  if (endDate) {
    newEndDate = format(utcToZonedTime(subHours(addDays(new Date(endDate), 1), 9), 'UTC'), 'yyyy-MM-dd HH:mm:ss');
  }
  if (startDate) {
    newStartDate = format(utcToZonedTime(subHours(new Date(startDate), 9), 'UTC'), 'yyyy-MM-dd HH:mm:ss');
  }
  return [newStartDate, newEndDate];
};
