import Excel from "exceljs";

/**
 * 엑셀 다운로드 처리
 * @param fileName 파일 & 시트명
 * @param columns 1열 데이터 정보
 * @param data data
 */
export const ExcelDown = async (fileName: string, columns: Object[], data: Object[]) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  worksheet.columns = columns;
  worksheet.addRows(data);

  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), `${fileName}.xlsx`);
};
