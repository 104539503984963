import {IGetBooksOrderLogData, IGetBooksOrderLogParams} from "../models/BooksOrderLog";
import axios, {AxiosPromise} from "axios";

/**
 * 해당 주문 로그 데이터 가져오기
 * @param params IGetBooksOrderLogParams
 * @return {} IGetBooksOrderLogData
 */
export const booksOrderLogDataSearchList = (params: IGetBooksOrderLogParams): AxiosPromise<IGetBooksOrderLogData[]> => {
  return axios.get(`/api/order/books/log?boNo=${params.bo_no}${params.boc_no ? `&bocNo=${params.boc_no}` : ''}`);
};
