import {
  IGetBooksOrderDetailInfoData,
  IGetBooksOrderDetailInfoDataResponse, ISetOrderAddressChangeParams,
} from "../models/BooksOrder";
import {AxiosError} from "axios";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./index";
import {Error} from "../models/Error";
import {messageAdd, showMessage} from "./message.slice";
import {getOrderDetailInfoData, setOrderAddressChange} from "../api/booksOrder";

export interface IBooksOrderState {
  orderDetailInfo: IGetBooksOrderDetailInfoData[];
  orderUserId: number;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBooksOrderState = {
  error: null,
  orderDetailInfo: [],
  orderUserId: 0,
  isLoading: false,
};

const booksOrderSlice = createSlice({
  name: 'booksOrder',
  initialState,
  reducers: {
    requestStart: (state: IBooksOrderState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestFailure: (state: IBooksOrderState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getDetailDataSuccess: (state: IBooksOrderState, action: PayloadAction<IGetBooksOrderDetailInfoDataResponse>) => {
      state.error = null;
      state.isLoading = false;
      state.orderDetailInfo = action.payload.dataList;
      state.orderUserId = action.payload.orderUserId;
    },
    orderAddressChangeSuccess: (state: IBooksOrderState) => {
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestFailure,
  getDetailDataSuccess,
  orderAddressChangeSuccess,
} = booksOrderSlice.actions;

export default booksOrderSlice.reducer;

export const getBooksOrderDetailData = (boNo: number, userId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await getOrderDetailInfoData(boNo);
    const data: IGetBooksOrderDetailInfoDataResponse = {
      dataList: response.data,
      orderUserId: userId,
    };
    dispatch(getDetailDataSuccess(data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const orderAddressChange = (params: ISetOrderAddressChangeParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await setOrderAddressChange(params);
    dispatch(orderAddressChangeSuccess());
    dispatch(messageAdd(showMessage('success', '변경되었습니다.')));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};
