import React, {FC} from "react";
import {IGetBooksOrderLogData} from "../../models/BooksOrderLog";
import {Button, Modal, Table} from "antd";
import {useDispatch} from "react-redux";
import {tableStyle} from "../../style/antdCssProperty";
import {format} from "date-fns";
import {ColumnsType} from "antd/lib/table";

interface IProps {
  visible: boolean;
  orderLogInfoData: IGetBooksOrderLogData[];

  onCancel(): void;
}

const BooksOrderLogModal: FC<IProps> = ({visible, orderLogInfoData, onCancel}) => {
  const logInfoColumns: ColumnsType<IGetBooksOrderLogData> = [
    {
      title: '변경일',
      width: 200,
      render: (record: IGetBooksOrderLogData) => {
        const date = new Date(record.createdAt);
        return format(date, 'yyyy-MM-dd kk:mm:ss');
      },
    },
    {
      title: '변경 내용',
      render: (record: IGetBooksOrderLogData) => {
        return <>
          {record.comment}<br/><br/>
          {record.changeContents}
        </>;
      },
    },
    {
      title: '변경자',
      width: 200,
      dataIndex: 'implementer',
    },
  ];

  return (
    <>
      <Modal title="주문 변경 기록"
             centered
             visible={visible}
             width={1200}
             onCancel={onCancel}
             footer={[
               <Button key="back" onClick={onCancel}>닫기</Button>,
             ]}>
        <Table style={tableStyle}
               dataSource={orderLogInfoData}
               size='small'
               columns={logInfoColumns}
               rowKey={(record) => `${record.no}`}
               pagination={false}/>
      </Modal>
    </>
  );
};

export default BooksOrderLogModal;
