import { FC } from 'react';
import { Button } from 'antd';
import { divStyle } from '../../style/antdCssProperty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { format, subDays, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { AddComma, excelInit } from '../../libs/helper';
import { doDeletePaymentInit } from '../../store/payment.slice';
import { getPaymentExcel } from '../../api/payment.api';

interface IProps {
  paymentMasterId: number;

  onEmailSubmitClick(): void;
}

const TutorPaymentBox: FC<IProps> = ({ paymentMasterId, onEmailSubmitClick }) => {
  const dispatch = useDispatch();

  const { paymentMaster, tutorPaymentList } = useSelector((root: RootState) => root.paymentState);
  const { user } = useSelector((root: RootState) => root.authState);

  const getDateString = () => {
    if (paymentMaster) {
      return `${format(new Date(paymentMaster.startDateTime), 'yyyy-MM-dd')} ~ ${format(subDays(new Date(paymentMaster.endDateTime), 1), 'yyyy-MM-dd')}`;
    } else {
      return '';
    }
  };

  const onExcelClick = async () => {
    const { data } = await getPaymentExcel(paymentMasterId);
    await excelInit('tutorPayment', 'tutorPayment', data.sheet1, data.sheet2);
  };

  const onInitClick = () => {
    dispatch(doDeletePaymentInit(paymentMasterId));
  };

  const getLatestDate = () => {
    if (!paymentMaster || !tutorPaymentList) return;

    let recentDate: Date = new Date(paymentMaster.updatedAt);
    tutorPaymentList.forEach(payment => {
      const isLatest = isAfter(new Date(payment.updatedAt), recentDate);
      if (isLatest) recentDate = new Date(payment.updatedAt);
    });

    return format(utcToZonedTime(new Date(recentDate), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss')
  }

  return (
    <div style={{ ...divStyle, display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <strong style={{ marginTop: 'auto', marginBottom: 'auto' }}>{paymentMaster?.title ?? ''}</strong>
        <p style={{
          display: 'inline-block',
          marginLeft: '2rem',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}>기간: {getDateString()}</p>
      </div>
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <p style={{
          display: 'inline-block',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}>총 {paymentMaster?.total ?? 0}건</p>
        <p style={{ display: 'inline-block', marginLeft: '2rem', marginTop: 'auto', marginBottom: 'auto' }}>Total:
          ${paymentMaster ? AddComma(paymentMaster.totalPay, true) : 0}</p>
      </div>
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        최근 수정일시: {getLatestDate()}
      </div>
      <div>
        <Button
          onClick={onInitClick}
          style={{ marginRight: '0.5rem' }}
          disabled={paymentMaster?.isPublished || user === null || user.id !== 1677}
        >
          초기화
        </Button>
        <Button
          onClick={onEmailSubmitClick}
          style={{ marginRight: '0.5rem' }}
          disabled={paymentMaster?.isPublished || user === null || user.id !== 1677}
        >
          마감 &amp; 이메일 발송
        </Button>
        <Button onClick={onExcelClick}>
          엑셀
        </Button>
      </div>
    </div>
  );
};

export default TutorPaymentBox;
