import React, { FC, useEffect, useState } from 'react';
import { Menu, Dropdown, Tabs, PageHeader, Breadcrumb, Descriptions, Modal, Input, message, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { useRouteMatch, Link } from 'react-router-dom';
import { userRead } from '../store/user.slice';
import CustomerInfo from '../components/Customer/CustomerInfo';
import { doGetStudent, doGetStudentTrialList, doPostStudentTrial, initStudent } from '../store/student.slice';
import { descriptionContentStyle, descriptionLabelStyle, divStyle } from '../style/antdCssProperty';
import Loader from '../components/Loader';
import { doGetUserTransactionList, initIsUpdated } from '../store/userTransaction.slice';
import {
  IStudentUnSubscription,
  IStudentUserSubscriptionByUser,
  IUserSubscriptionListParam,
} from '../models/UserSubscription';
import { doGetUserSubscriptionList, doPostUnsubscription } from '../store/userSubscription.slice';
import { IUserTransaction, IUserTransactionParam } from '../models/UserTransaction';
import SubscriptionTable, { StudentUserSubscription } from '../components/Subscription/SubscriptionTable';
import VideoCallSubscribeTable from '../components/Transaction/VideoCallSubscribeTable';
import { getUserVirtualLogin } from '../api/auth';
import CustomerCourseTable from '../components/Customer/CustomerCourseTable';
import { doGetStudentCourseList, doPatchStudentCourse } from '../store/studentCourse.slice';
import CustomerCourseChangeModal from '../components/Customer/CustomerCourseChangeModal';
import { doGetCourseList } from '../store/course.slice';
import { IStudentCourseUpdate } from '../models/studyCourse.model';
import { doGetSchedule2MetaList } from '../store/schedule2.slice';
import { ISchedule2MetaParam } from '../models/schedule2.model';
import CustomerScheduleTable from '../components/Customer/CustomerScheduleTable';
import CustomerAlarmTalkTable from '../components/Customer/CustomerAlarmTalkTable';
import { doGetMessageLogList } from '../store/messageLog.slice';
import CustomerTrialForm, { IValues } from '../components/Customer/CustomerTrialForm';
import { format } from 'date-fns';
import CustomerTrailTable from '../components/Customer/CustomerTrailTable';
import { LoginProvider } from '../models/Enum';
import CustomerUserTransactionEdit from '../components/Customer/CustomerUserTransactionEdit';

interface IMatch {
  id?: string;
}

const { TabPane } = Tabs;
const { TextArea } = Input;

const CustomerPage: FC = () => {
  const { user, isLoading: userIsLoading } = useSelector((state: RootState) => state.userState);
  const {
    isLoading: studentLoading,
    customerStudent,
  } = useSelector((state: RootState) => state.studentState);
  const {
    studentUserSubscriptions,
    isLoading: userSubscriptionIsLoading,
  } = useSelector((root: RootState) => root.userSubscriptionState);
  const {
    userTransactions,
    isLoading: userTransactionIsLoading, isUpdated,
  } = useSelector((state: RootState) => state.userTransactionState);
  const {
    studentCourseList,
  } = useSelector((state: RootState) => state.studyCourseState);
  const { courses } = useSelector((state: RootState) => state.courseState);
  const { schedule2MetaList } = useSelector((state: RootState) => state.schedule2State);
  const [studentIndex, setStudentIndex] = useState(0);
  const [userSubscriptionVisible, setUserSubscriptionVisible] = useState(false);
  const [userSubscriptionRecord, setUserSubscriptionRecord] = useState<IStudentUserSubscriptionByUser>();
  const [reasonDetail, setReasonDetail] = useState('');
  const [customerCourseChangeVisible, setCustomerCourseChangeVisible] = useState(false);
  const [productId, setProductId] = useState(0);
  const [studentCourseId, setStudentCourseId] = useState(0);
  const [key, setKey] = useState('1');
  const [userTransaction, setUserTransaction] = useState<IUserTransaction>();
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();

  useEffect(() => {
    if (isUpdated) {
      dispatch(initIsUpdated());
      setUserTransaction(undefined);
    }
  }, [isUpdated]);

  useEffect(() => {
    dispatch(userRead(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (user) {
      if (user.students.length >= 1) {
        dispatch(doGetStudent(user.students[studentIndex].id));
      } else {
        dispatch(initStudent());
      }
    }
  }, [dispatch, studentIndex, user]);

  const onTabsChange = (key: string) => {
    setKey(key);
    setUserTransaction(undefined);
    switch (key) {
      case '1':
        if (user && user.students.length >= 1) {
          dispatch(doGetStudent(user.students[studentIndex].id));
        }
        break;
      case '2':
        if (user?.students && user.students.length >= 1) {
          const param: IUserSubscriptionListParam = {
            studentId: user.students[studentIndex].id,
          };
          dispatch(doGetUserSubscriptionList(param));
        }
        break;
      case '3':
        if (user?.students && user.students.length >= 1) {
          const param: IUserTransactionParam = {
            studentId: user.students[studentIndex].id,
          };
          dispatch(doGetUserTransactionList(param));
        }
        break;
      case '4':
        if (user?.students && user.students.length >= 1) {
          const params: ISchedule2MetaParam = {
            studentId: user.students[studentIndex].id,
          };
          dispatch(doGetSchedule2MetaList(params));
        }
        break;
      case '7':
        if (user?.students && user.students.length >= 1) {
          dispatch(doGetStudentCourseList(user.students[studentIndex].id));
        }
        break;
      case '8':
        user && dispatch(doGetMessageLogList({ userId: user.id }));
        break;
      case '9':
        if (user && user.students.length >= 1) {
          dispatch(doGetCourseList());
          dispatch(doGetStudentTrialList(user.id, user.students[studentIndex].id));
        }
        break;
    }
  };

  useEffect(() => {
    onTabsChange(key);
  }, [studentIndex]);

  const showUserSubscriptionModal = (record: IStudentUserSubscriptionByUser) => {
    setUserSubscriptionRecord(record);
    setUserSubscriptionVisible(true);
  };

  const menu = (
    <Menu style={{
      float: 'left',
    }}>
      {
        user?.students && user.students.length >= 1 &&
        user.students.map((item, index) =>
          <Menu.Item key={`student-${item.id}`} onClick={() => onStudentClick(index)}>{item.name}</Menu.Item>,
        )
      }
    </Menu>
  );

  const handleVirtualLogin = async () => {
    if (!user) {
      message.warn('로그인할 유저정보가 존재하지 않습니다.');
      return;
    }
    getUserVirtualLogin(user.email).then((response) => {
      message.success('가상로그인 성공');
    }).catch((e) => {
      message.warning('가상로그인 하는데 문제가 발생했습니다.');
    });
  };

  const onStudentClick = (index: number) => {
    setStudentIndex(index);
    setUserTransaction(undefined);
  };

  const handleUserSubscriptionOk = () => {
    if (!user) {
      message.warn('user: ');
      return;
    }
    if (!userSubscriptionRecord) {
      message.warn('record: ');
      return;
    }
    if (!reasonDetail) {
      message.warn('이유를 작성해주세요.');
      return;
    }
    const data: IStudentUnSubscription = {
      subscriptionId: userSubscriptionRecord.id,
      studentId: userSubscriptionRecord.student.id,
      unsubscriptionReason: 'LMS 취소',
      reasonDetail,
      staffId: user.id,
    };
    dispatch(doPostUnsubscription(data));
    setUserSubscriptionVisible(false);
    setReasonDetail('');
  };

  const handleUserSubscriptionCancel = () => {
    setUserSubscriptionVisible(false);
    setReasonDetail('');
  };

  const onReasonDetailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setReasonDetail(event.target.value);
  };

  const handleCustomerCourseChangeVisibleOk = (courseId: number) => {
    if (productId === 0 || studentCourseId === 0) {
      return;
    }
    if (user?.students && user.students.length >= 1) {
      const filter = studentCourseList.filter((item) => item.isValid === true && item.course.id);
      if (filter.length >= 1 && filter[0].course.id === courseId) {
        message.warn('이전 코스랑 같습니다. 다른 코스를 선택하여 주세요.');
        return;
      }
      const data: IStudentCourseUpdate = {
        studentId: user.students[studentIndex].id,
        courseId,
        productId,
      };
      dispatch(doPatchStudentCourse(studentCourseId, data));
    }
    setCustomerCourseChangeVisible(false);
  };

  const handleCustomerCourseChangeVisibleCancel = () => {
    setCustomerCourseChangeVisible(false);
  };

  const showCustomerCourseChangeModal = (productId: number, id: number) => {
    setCustomerCourseChangeVisible(true);
    setProductId(productId);
    setStudentCourseId(id);
    dispatch(doGetCourseList(productId));
  };

  const onCustomerTrialFormFinish = (values: IValues) => {
    if (user && user.students.length >= 1) {
      const startDate = format(values.startDate, 'yyyy-MM-dd');
      const endDate = format(values.endDate, 'yyyy-MM-dd');
      dispatch(doPostStudentTrial(user.id, user.students[studentIndex].id, {
        courseId: values.courseId,
        startDate,
        endDate,
        totalCassCount: values.totalClassCount,
      }));
    }
  };

  const getLoginMethod = (): string => {
    let str = '';
    if (user && user.userLoginMethods.length >= 1) {
      for (const item of user.userLoginMethods) {
        if (str !== '') {
          str += ', ';
        }
        switch (item.loginProvider) {
          case LoginProvider.EMAIL:
            str += '이메일';
            break;
          case LoginProvider.FACEBOOK:
            str += '페이스북';
            break;
          case LoginProvider.GOOGLE:
            str += '구글';
            break;
          case LoginProvider.KAKAO:
            str += '카카오';
            break;
          case LoginProvider.NAVER:
            str += '네이버';
            break;
        }
      }
    }
    return str;
  };

  const onTransactionTableClick = (e: React.MouseEvent<HTMLAnchorElement>, item: IUserTransaction) => {
    e.preventDefault();
    setUserTransaction(item);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='/customer'>회원관리</Link></Breadcrumb.Item>
        <Breadcrumb.Item>회원정보</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='회원정보'>
      </PageHeader>
      {
        userIsLoading ? (
          <Loader />
        ) : (
          <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
            <Descriptions.Item label='이름' span={1}>{user ? user.name : ''}</Descriptions.Item>
            <Descriptions.Item label='이메일' span={1}>{user ? user.email : ''}</Descriptions.Item>
            <Descriptions.Item label='전화번호' span={1}>{user ? user.phone : ''}</Descriptions.Item>
            <Descriptions.Item label='가입정보' span={1}>{getLoginMethod()}</Descriptions.Item>
          </Descriptions>
        )
      }
      <span>
        <p>등록자녀 : {user?.students ? user.students.length : 0}</p>
        <Dropdown overlay={menu}>
        <p className='ant-dropdown-link' style={{
          float: 'right',
        }}>
          {(user && user.students.length >= 1) ? user.students[studentIndex].name : '선택'} <DownOutlined />
        </p>
      </Dropdown>
      </span>
      <Tabs defaultActiveKey='1' onChange={onTabsChange}>
        <TabPane tab='상세정보' key='1'>
          {
            studentLoading ? (
              <Loader />
            ) : <CustomerInfo student={customerStudent} />
          }
        </TabPane>
        <TabPane tab='구독내역' key='2'>
          {
            userSubscriptionIsLoading ? (
              <Loader />
            ) : (
              <SubscriptionTable
                data={studentUserSubscriptions as StudentUserSubscription[]}
                deactive={null}
                showModal={showUserSubscriptionModal}
              />
            )
          }
        </TabPane>
        <TabPane tab='결재내역' key='3'>
          {
            userTransactionIsLoading ? <Loader /> :
              userTransaction ? <CustomerUserTransactionEdit userTransaction={userTransaction} /> :
                <VideoCallSubscribeTable userTransactions={userTransactions} onClick={onTransactionTableClick} />
          }
        </TabPane>
        <TabPane tab='일정' key='4'>
          {
            <CustomerScheduleTable schedule2MetaList={schedule2MetaList} />
          }
        </TabPane>
        <TabPane tab='수강내역' key='7'>
          <CustomerCourseTable studyCourseList={studentCourseList} showModal={showCustomerCourseChangeModal} />
        </TabPane>
        <TabPane tab='알림톡 내역' key='8'>
          <CustomerAlarmTalkTable />
        </TabPane>
        <TabPane tab='체험단' key='9'>
          <CustomerTrialForm onFinish={onCustomerTrialFormFinish} />
          <CustomerTrailTable />
        </TabPane>
      </Tabs>
      <div style={{
        ...divStyle,
        marginTop: '1.5rem',
      }}>
        <Button type='primary' onClick={handleVirtualLogin}>가상로그인</Button>
      </div>
      <Modal title='구독 취소' visible={userSubscriptionVisible} onOk={handleUserSubscriptionOk}
             onCancel={handleUserSubscriptionCancel}>
        <TextArea value={reasonDetail} onChange={onReasonDetailChange} />
      </Modal>
      <CustomerCourseChangeModal visible={customerCourseChangeVisible} courseList={courses}
                                 onOk={handleCustomerCourseChangeVisibleOk}
                                 onCancel={handleCustomerCourseChangeVisibleCancel} />
    </>
  );
};

export default CustomerPage;
