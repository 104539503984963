import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IGetBooksOrderLogData, IGetBooksOrderLogParams, IGetBooksOrderLogResponse} from "../models/BooksOrderLog";
import {AppThunk} from "./index";
import {AxiosError} from "axios";
import {booksOrderLogDataSearchList} from "../api/booksOrderLog";
import {Error} from "../models/Error";
import {messageAdd, showMessage} from "./message.slice";

export interface IBooksOrderLogState {
  logDataList: IGetBooksOrderLogData[];
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBooksOrderLogState = {
  logDataList: [],
  isLoading: false,
  error: null,
}

const booksOrderLogSlice = createSlice({
  name: 'booksOrderLog',
  initialState,
  reducers: {
    requestStart: (state: IBooksOrderLogState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestFailure: (state: IBooksOrderLogState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    dataListSuccess: (state: IBooksOrderLogState, action: PayloadAction<IGetBooksOrderLogResponse>) => {
      state.error = null;
      state.isLoading = false;
      state.logDataList = action.payload.dataList;
    },
  },
});

export const {
  requestStart,
  requestFailure,
  dataListSuccess,
} = booksOrderLogSlice.actions;

export default booksOrderLogSlice.reducer;

export const booksOrderLogData = (params: IGetBooksOrderLogParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await booksOrderLogDataSearchList(params);
    const data: IGetBooksOrderLogResponse = {
      dataList: response.data,
    };
    dispatch(dataListSuccess(data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};
