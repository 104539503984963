import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { IGetUserListSuccess, User, UserParam, UserRead } from '../models/User';
import { AxiosError } from 'axios';
import { list, read } from '../api/user';

interface IUserState {
  users: User[];
  user: UserRead | null;
  total: number;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IUserState = {
  users: [],
  user: null,
  total: 0,
  isLoading: false,
  error: null,
};

const userSlicer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    requestStart: (state: IUserState) => {
      state.user = null;
      state.error = null;
      state.isLoading = true;
    },
    listSuccess: (state: IUserState, action: PayloadAction<IGetUserListSuccess>) => {
      state.total = action.payload.total;
      state.users = action.payload.data;
      state.error = null;
      state.isLoading = false;
    },
    readSuccess: (state: IUserState, action: PayloadAction<UserRead>) => {
      state.user = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IUserState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  listSuccess,
  readSuccess,
  requestFailure,
} = userSlicer.actions;

export default userSlicer.reducer;

export const userList = (param?: UserParam): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(param);
    const data: IGetUserListSuccess = {
      data: response.data, total: response.headers['total'],
    };
    dispatch(listSuccess(data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const userRead = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await read(id);
    dispatch(readSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};
