import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IUserTransaction } from '../../models/UserTransaction';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { format } from 'date-fns';
import { RetryType, UserTransactionChannel } from '../../models/Enum';

interface IProps {
  userTransactions: IUserTransaction[];

  onClick?(e: React.MouseEvent<HTMLAnchorElement>, userTransaction: IUserTransaction): void;
}

const VideoCallSubscribeTable: FC<IProps> = ({ userTransactions, onClick }) => {
  const getPayType = (record: IUserTransaction) => {
    if (record.channel === UserTransactionChannel.CRON) return '재결제';
    switch (record.retryType) {
      case RetryType.NORMAL_PAY:
        return '신규 결제';
      case RetryType.CHANGE_CARD_PAY:
        return '카드 변경 결제';
      case RetryType.RETRY_PAY:
        return '재시도 결제';
    }
  };

  const columns: ColumnsType<IUserTransaction> = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '주문번호',
      dataIndex: 'mbrRefNo',
      render: (value, record) => onClick ?
        <a onClick={(e) => onClick(e, record)} style={LinkStyle}>{record.mbrRefNo}</a> : record.mbrRefNo,
    },
    {
      title: '승인번호',
      dataIndex: 'appNo',
    },
    {
      title: '수업구분',
      key: 'productName',
      render: (value, record) => (
        record.product.name
      ),
    },
    {
      title: '과정',
      key: 'courseName',
      render: (value, record) => (
        record.course.name
      ),
    },
    {
      title: '고객',
      key: 'userName',
      render: (value, record) => (
        // customer/115
        <Link to={`/customer/${record.user.id}`} style={LinkStyle}>{record.user.name} ({record.user.id})</Link>
      ),
    },
    {
      title: '전화번호',
      render: (value, record) => record.user.phone,
    },
    {
      title: '학생',
      key: 'studentName',
      render: (value, record) => (
        `${record.student.name} (${record.student.id})`
      ),
    },
    {
      title: '이메일',
      key: 'email',
      render: (value, record) => (
        record.user.email
      ),
    },
    {
      title: '결제대금',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: '할인금액',
      key: 'couponAmount',
      dataIndex: 'couponAmount',
    },
    {
      title: '상태',
      key: 'status',
      render: (value, record) => (
        record.deletedAt ? <>
          결제취소<br />{format(new Date(record.deletedAt), 'yyyy.MM.dd')}
        </> : '결제완료'
      ),
    },
    {
      title: '결제회차',
      dataIndex: 'sequence',
    },
    {
      title: '결제일',
      key: 'createdAt',
      render: (value, record) => (
        format(new Date(record.createdAt), 'yyyy.MM.dd HH:mm:ss')
      ),
    },
    {
      title: '구분',
      key: 'channel',
      render: (value, record) => getPayType(record),
    },
    {
      title: '수업 취소',
      dataIndex: 'cancelClassCount',
    },
    // {
    //   title: '',
    //   key: 'deletedAt',
    //   render: (value, record) => (
    //     record.deletedAt ? '사유' : '취소'
    //   ),
    // },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={userTransactions}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default VideoCallSubscribeTable;
