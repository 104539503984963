import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { BoardCategory, BoardListItem, BoardRequest, IAttachment, IBoardParams, IBoardRead } from 'models/Board';
import {
  create,
  list,
  read,
  update,
  remove,
  boardCategoryList,
  boardFilesList,
  boardFilesRemove,
  boardFilesThumbnail,
} from 'api/board';
import { AxiosError, AxiosResponse } from 'axios';
import { messageAdd, showMessage } from './message.slice';
import { Error } from '../models/Error';
import { message } from 'antd';

interface IBoardState {
  boards: BoardListItem[];
  board?: IBoardRead;
  boardCategories: BoardCategory[];
  boardFiles: IAttachment[];
  total: number;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IBoardState = {
  boards: [],
  boardCategories: [],
  boardFiles: [],
  total: 0,
  isLoading: false,
  error: null,
};

const boardSlicer = createSlice({
  name: 'board',
  initialState,
  reducers: {
    requestStart: (state: IBoardState) => {
      state.error = null;
      state.isLoading = true;
    },

    listSuccess: (state: IBoardState, action: PayloadAction<AxiosResponse<BoardListItem[]>>) => {
      state.boards = action.payload.data;
      state.total = action.payload.headers['total'];
      state.error = null;
      state.isLoading = false;
    },

    notResponseSuccess: (state: IBoardState) => {
      state.error = null;
      state.isLoading = false;
    },

    removeSuccess: (state: IBoardState, action: PayloadAction<number>) => {
      if (state.boards !== null) {
        state.boards = state.boards.filter(item => item.id !== action.payload);
      }
      state.error = null;
      state.isLoading = false;
    },

    requestFailure: (state: IBoardState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    readSuccess: (state: IBoardState, action: PayloadAction<IBoardRead>) => {
      state.board = action.payload;
      state.error = null;
      state.isLoading = false;
    },

    boardCategorySuccess: (state: IBoardState, action: PayloadAction<BoardCategory[]>) => {
      state.boardCategories = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    boardCategoryFailure: (state: IBoardState, action: PayloadAction<AxiosError>) => {
      state.boardCategories = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    boardFileListStart: (state: IBoardState) => {
      state.boardFiles = [];
      state.error = null;
      state.isLoading = true;
    },
    boardFileListSuccess: (state: IBoardState, action: PayloadAction<IAttachment[]>) => {
      state.boardFiles = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    boardFileListFailure: (state: IBoardState, action: PayloadAction<AxiosError>) => {
      state.boardFiles = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    boardFileDeleteStart: (state: IBoardState) => {
      state.error = null;
      state.isLoading = true;
    },
    boardFileDeleteSuccess: (state: IBoardState, action: PayloadAction<number>) => {
      state.boardFiles = state.boardFiles.filter((item) => item.id !== action.payload);
      state.error = null;
      state.isLoading = false;
    },
    boardFileDeleteFailure: (state: IBoardState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  listSuccess,
  notResponseSuccess,
  removeSuccess,
  requestFailure,
  readSuccess, boardCategoryFailure,
  boardCategorySuccess,
  boardFileListStart,
  boardFileListSuccess,
  boardFileListFailure,
  boardFileDeleteStart,
  boardFileDeleteSuccess,
  boardFileDeleteFailure,
} = boardSlicer.actions;

export default boardSlicer.reducer;

export const boardCreate = (data: BoardRequest, formData: FormData): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data: { id } } = await create(data);
    await boardFilesThumbnail(id, formData);
    dispatch(messageAdd(showMessage('success', 'create board!!')));
    dispatch(notResponseSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const boardList = (params?: IBoardParams): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(params);
    dispatch(listSuccess(response));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const boardRead = (boardId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await read(boardId);
    dispatch(readSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const boardUpdate = (boardId: number, data: BoardRequest, formData?: FormData): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await update(boardId, data);
    if (formData) {
      await boardFilesThumbnail(boardId, formData);
    }
    dispatch(messageAdd(showMessage('success', 'board update')));
    dispatch(notResponseSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const boardRemove = (boardId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await remove(boardId);
    dispatch(messageAdd(showMessage('success', 'board remove')));
    dispatch(removeSuccess(boardId));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const doBoardCategoryList = (bmId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await boardCategoryList(bmId);
    dispatch(boardCategorySuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doBoardFileList = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(boardFileListStart());
    const response = await boardFilesList(id);
    dispatch(boardFileListSuccess(response.data));
  } catch (e) {
    dispatch(boardFileListFailure(e));
  }
};

export const doBoardFileDelete = (attachmentId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(boardFileDeleteStart());
    await boardFilesRemove(attachmentId);
    dispatch(boardFileDeleteSuccess(attachmentId));
    message.success('삭제되었습니다');
  } catch (e) {
    dispatch(boardFileDeleteFailure(e));
  }
};
