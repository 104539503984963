export enum LoginProvider {
  EMAIL = 'email',
  KAKAO = 'kakao',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  NAVER = 'naver'
}

export enum UserType {
  TUTOR = 'tutor',
  STAFF = 'staff'
}

export enum Result {
  SUCCESS = 'success',
  FAIL = 'fail'
}

export enum EducationLevel {
  UNIVERSITY = '대학교',
  MASTER = '석사',
  DOCTOR = '박사',
}

export enum Certificate {
  TYTORCERTIFICATE = '교원자격증',
  TESOL = 'TESOL',
}

export enum Status {
  APPLYING = 'applying',
  FAIL = 'fail',
  SUBMIT = 'submit'
}

export enum Steps {
  SURVEY = 'survey',
  MOCK = 'mock',
  MOCK_RETRY = 'mock_retry',
  DOCUMENT = 'document',
  CONTRACT = 'contract'
}

export enum FileType {
  SURVEY = 'survey',
  MOCK = 'mock',
  MOCK_RETRY = 'mock_retry',
  VIDEO = 'video',
  RESUME = 'resume',
  PASSPORT = 'passport',
  PHOTO = 'photo',
  DEGREE = 'degree',
  BACKGROUND_CHECK = 'background_check',
  INDEPENDENT = 'independent',
  EXHIBIT_A = 'exhibit_A',
  NON_DISCLOSURE = 'non_disclosure'
}

export enum StaffStatus {
  ACTIVE = 'Active',
  HOLD = 'Hold',
  QUIT = 'Quit'
}

//enum('tutor_ready','reserved','studying','complete','student_cancel','fail')

export enum ScheduleStatus {
  TUTOR_READY = 'tutor_ready',
  RESERVED = 'reserved',
  STUDYING = 'studying',
  COMPLETE = 'complete',
  STUDENT_CANCEL = 'student_cancel',
  FAIL = 'fail',
}

export enum ScheduleColor {
  AVAILABLE_COLOR = '#f0f1c6',
  RESERVED_COLOR = '#c0e0e6',
  STUDY_COMPLETE_COLOR = '#bdd1ea',
  TRIAL_RESERVED_COLOR = '#d1c9df',
  TRIAL_COMPLETE_COLOR = '#f7c5c8',
  HOLIDAY_COLOR = '#fdd6b3'
}

export enum CouponType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum PublishEvent {
  REGISTER = 'REGISTER',
  TRIAL_CLASS = 'TRIAL_CLASS'
}

export enum IssueStatus {
  COMPLETE = 'complete',
  IN_PROCESS = 'in-process',
}

export enum IssueType {
  STUDENT = 'student',
  TUTOR = 'tutor',
}

export enum StudentSubstituteAgree {
  WAIT = 'WAIT',
  AGREE = 'AGREE',
  DENY = 'DENY'
}

export enum UserOrigin {
  E = 'e',
  K = 'k'
}

export enum CancelBy {
  TUTOR = 'tutor',
  STUDENT = 'student',
  STAFF = 'staff'
}

export enum FailReason {
  STUDENT_NOSHOW = 'STUDENT-NOSHOW',
  TUTOR_NOSHOW = 'TUTOR-NOSHOW',
  CANCEL2 = 'CANCEL2',
  CANCEL3 = 'CANCEL3',
  STUDENT_CANCEL = 'STUDENT-CANCEL',
  IT_PROBLEM = 'IT-PROBLEM',
  ALL_NOSHOW = 'ALL-NOSHOW',
  STUDENT_IT_ISSUE = 'STUDENT-IT-ISSUE',
  TUTOR_IT_ISSUE = 'TUTOR-IT-ISSUE',
  COMPANY_IT_ISSUE = 'COMPANY-IT-ISSUE',
  OTHER = 'OTHER',
  TEMP ='TEMP'
}

export enum StudyUserType {
  TUTOR = 'tutor',
  STUDENT = 'student'
}

export enum ScheduleType {
  SUCCESS = 'SUCCESS',
  STUDENT_NOSHOW = 'STUDENT-NOSHOW',
  ALL_NOSHOW = 'ALL-NOSHOW',
  STUDENT_IT_ISSUE = 'STUDENT-IT-ISSUE',
  TUTOR_IT_ISSUE = 'TUTOR-IT-ISSUE',
  COMPANY_IT_ISSUE = 'COMPANY-IT-ISSUE',
  CANCEL2 = 'CANCEL2',
  CANCEL3 = 'CANCEL3',
  TUTOR_NOSHOW = 'TUTOR-NOSHOW',
  STUDENT_CANCEL = 'STUDENT-CANCEL',
}

export enum UserTransactionChannel {
  WEB = 'web',
  CRON = 'cron'
}

export enum LogType {
  CANCEL2 = 'CANCEL2',
  CANCEL3 = 'CANCEL3',
  NO_SHOW = 'NO-SHOW',
  COMPLAINT = 'COMPLAINT',
  SUBSTITUE = 'SUBSTITUE',
  REFERRAL = 'REFERRAL',
}

export enum ExtraType {
  BONUS = 'bonus',
  DEDUCTION = 'deduction',
}

export enum LevelTestFail {
  S_NO_SHOW = 'S-No Show',
  T_NO_SHOW = 'T-No Show',
  ALL_NO_SHOW = 'ALL-No Show',
  S_IT_ISSUE = 'S-IT Issue',
  T_IT_ISSUE = 'T-IT Issue',
  C_IT_ISSUE = 'C-IT Issue'
}

export enum RetryType {
  NORMAL_PAY = 'normalPay',
  CHANGE_CARD_PAY = 'changeCardPay',
  RETRY_PAY = 'retryPay'
}

export enum ProductCourseType {
  REGULAR = 'REGULAR',
  CULTURE = 'CULTURE',
  TRIAL = 'TRIAL',
}

export const productCourseTypes = Object.keys(
  ProductCourseType,
) as ProductCourseType[];

export enum BrotherApplyState {
  APPLY = 'apply',
  SUCCESS = 'success',
  REJECT = 'reject',
}
