import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Select, Input, message, Button } from 'antd';
import {divStyle, searchFormDivStyle, searchFormFilterNameSpanStyle} from '../../style/antdCssProperty';
import { UserParam } from '../../models/User';
import { list } from '../../api/user';
import { excelInit } from '../../libs/helper';
import { FileExcelOutlined } from '@ant-design/icons';
import { getSession } from '../../libs/session';
import CustomDatePicker from "../common/CustomDatePicker";

interface IProps {
  onSubmit(param: UserParam): void;
}

export interface IExcelData {
  id: number;
  name: string;
  email: string;
  phone: string;
  studentName: string;
  isSubscription: string;
}

const { Option } = Select;
const { Search } = Input;

const CustomerFilterBox: FC<IProps> = ({ onSubmit }) => {
  const [search, setSearch] = useState('');
  const [select, setSelect] = useState('선택');
  const [subscriptionStateSelect, setSubscriptionStateSelect] = useState('상태');
  const [joinRootSelect, setJoinRootSelect] = useState('가입경로');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    const sessionParams = getSession<UserParam>('customer');
    if (sessionParams) {
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      }
      if (sessionParams.type) {
        setSelect(sessionParams.type);
      }
      if (sessionParams.subscriptionState) {
        setStartDate(sessionParams.subscriptionState);
      }
      if (sessionParams.joinRoot) {
        setJoinRootSelect(sessionParams.joinRoot);
      }
      if (sessionParams.startDate) {
        setStartDate(sessionParams.startDate);
      }
      if (sessionParams.endDate) {
        setEndDate(sessionParams.endDate);
      }
    }
  }, []);

  const onChange = (value: string) => {
    setSelect(value);
  };

  const onChange_State = (value: string) => {
    setSubscriptionStateSelect(value);
  };

  const onChange_JoinRoot = (value: string) => {
    setJoinRootSelect(value);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onStartDateChange = (date: any, dateString: string) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    setEndDate(dateString);
  };

  const onDataInit = () => {
    if (search && select === '선택') {
      message.warn('타입을 골라주세요.');
    }
    const data: UserParam = {
      type: select,
      search: search.trim(),
      subscriptionState: subscriptionStateSelect,
      joinRoot: joinRootSelect,
      startDate: startDate,
      endDate: endDate,
    };
    return data;
  };

  const onSearch = () => {
    const data = onDataInit();
    if (data) {
      onSubmit(data);
    }
  };

  const onExcelClick = async () => {
    const data = onDataInit();
    if (data) {
      data.all = true;

      const response = await list(data);
      if (response.data.length >= 1) {
        const excelData: IExcelData[] = [];
        for (const item of response.data) {
          excelData.push({
            id: item.id,
            name: item.name,
            email: item.email,
            phone: item.phone,
            studentName: item.studentName ? item.studentName : '',
            isSubscription: item.userSubscriptionId ? '수강중' : '',
          });
        }
        await excelInit('customer', 'customer', excelData);
      }
    }
  };

  return (
    <div style={divStyle}>
      <div style={searchFormDivStyle}>
        <span style={searchFormFilterNameSpanStyle}>가입일</span>
        <CustomDatePicker value={startDate ? new Date(startDate) : undefined} onChange={onStartDateChange} />
        ~
        <CustomDatePicker value={endDate ? new Date(endDate) : undefined} onChange={onEndDateChange} />
      </div>
      <div style={searchFormDivStyle}>
        <Select
            style={{width: '10%'}}
            placeholder='select type'
            value={joinRootSelect}
            onChange={onChange_JoinRoot}>
          <Option value=''>가입경로</Option>
          <Option value='e'>초등</Option>
          <Option value='k'>킨더</Option>
        </Select>
        <Select
            style={{width: '10%'}}
            placeholder='select type'
            value={subscriptionStateSelect}
            onChange={onChange_State}>
          <Option value=''>상태</Option>
          <Option value='takingClasses'>수강중</Option>
          <Option value='-'>-</Option>
        </Select>
        <Select
            style={{width: '10%'}}
            placeholder='select type'
            value={select}
            onChange={onChange}>
          <Option value='email'>이메일(ID)</Option>
          <Option value='name'>학부모이름</Option>
          <Option value='studentName'>자녀명</Option>
          <Option value='studentName'>자녀 닉네임</Option>
          <Option value='phone'>전화번호</Option>
        </Select>
        <Search placeholder='input search' value={search} onSearch={onSearch} onChange={onSearchChange} style={{
          width: '30rem',
          marginRight: '0.5rem',
        }} />
        <Button icon={<FileExcelOutlined />} onClick={onExcelClick} style={{ marginLeft: '0.5rem', float: 'right' }} />
      </div>
      <div>
        <Button type='primary' style={{ marginRight: '0.5rem' }} onClick={onSearch}>검색</Button>
      </div>
    </div>
  );
};

export default CustomerFilterBox;
